<template>
  <layout-component>
    <div class="agreements">
      <h1>{{ $t("agreement.agreement") }}</h1>
      <table class="agreements__list">
        <thead>
          <tr>
            <th>{{ $t("agreement.name") }}</th>
            <th>{{ $t("agreement.customer_name") }}</th>
            <th>{{ $t("agreement.from") }}</th>
            <th>{{ $t("agreement.to") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(agreement, index) in agreements"
            :key="`agreements_${index}`"
            class="agreements__list__row"
          >
            <td>
              <router-link
                class="agreements__list__row__link"
                :to="{
                  name: 'admin.agreement.show',
                  params: { id: agreement.id },
                }"
              >
                {{ agreement.name }}
              </router-link>
            </td>
            <td>
              <router-link
                class="agreements__list__row__link"
                :to="{
                  name: 'admin.agreement.show',
                  params: { id: agreement.id },
                }"
              >
                {{ agreement.customerName }}
              </router-link>
            </td>
            <td>
              <router-link
                class="agreements__list__row__link"
                :to="{
                  name: 'admin.agreement.show',
                  params: { id: agreement.id },
                }"
              >
                {{ dateFormat(agreement.from) }}
              </router-link>
            </td>
            <td>
              <router-link
                class="agreements__list__row__link"
                :to="{
                  name: 'admin.agreement.show',
                  params: { id: agreement.id },
                }"
              >
                <template v-if="agreement.to">
                  {{ agreement.to }}
                </template>
                <template v-else>
                  {{ $t("agreement.ongoing") }}
                </template>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <button-component
        :route="{ name: 'admin.agreement.show', params: { id: 'new' } }"
      >
        {{ $t("agreement.add") }}
      </button-component>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

// DTO's
import { IAgreementDTO } from "../../../shared/dto/agreement.dto";

// Bootstrap
import { agreementService } from "../bootstrap";

// Services
import { AgreementService } from "../services/agreement.service";

// Components
import ButtonComponent from "./button.component.vue";
import LayoutComponent from "./layout.component.vue";

const $router = useRouter();

const agreements = ref<IAgreementDTO[]>([]);

onMounted(() => {
  getAgreements();
});

function dateFormat(date: string): string {
  return date.substr(0, 10);
}

function goToAgreement(agreementId: number): void {
  $router.push({
    name: "admin.agreement.show",
    params: { id: agreementId.toString() },
  });
}

async function getAgreements(): Promise<void> {
  agreements.value = await agreementService.index();
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.agreements {
  &__list {
    @include list(true);
    width: 100%;
    &__row {
      &__link {
        text-decoration: none;
        color: #000;
        padding: $list-padding;
        display: block;
      }
    }
  }
}
</style>
