<template>
  <modal-layout-component
    class="resident-form"
    :header="isEdit ? 'Redigera Boende' : 'Ny boende'"
    :show-buttons="true"
    :valid="isValid"
    :loading="loading"
    @abort="$emit('hide')"
    @save="save"
  >
    <input-component
      v-model="form.nationalId"
      :label="$t('resident.nationalId')"
      :disabled="loading"
    />
    <input-component
      v-model="form.firstName"
      :label="$t('resident.firstName')"
      :disabled="loading"
    />
    <input-component
      v-model="form.lastName"
      :label="$t('resident.lastName')"
      :disabled="loading"
    />
    <input-component
      v-model="form.phone"
      :label="$t('resident.phone')"
      :disabled="loading"
    />
    <input-component
      v-model="form.email"
      :label="$t('resident.email')"
      :disabled="loading"
    />
  </modal-layout-component>
</template>
<script lang="ts" setup>
// Libs
import { ref, computed, onMounted, defineEmits } from "vue";

// DTO's
import { IResidentDTO } from "../../../shared/dto/resident.dto";

// Bootstrap
import { residentService } from "../bootstrap";

// Components
import InputComponent from "./input.component.vue";
import ModalLayoutComponent from "./modal-layout.component.vue";

interface IResidencyForm {
  nationalId: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

const props = defineProps<{
  resident?: IResidentDTO;
}>();

const $emit = defineEmits(["close", "hide"]);

const isEdit = computed(() => props.resident !== undefined);
const isValid = computed(() => form.value.nationalId !== "");

const form = ref<IResidencyForm>({
  nationalId: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
});
const loading = ref<boolean>(false);

onMounted(() => {
  setTimeout(() => {}, 1000);

  if (props.resident !== undefined) {
    form.value.nationalId = props.resident.nationalId;
    form.value.firstName = props.resident.firstName || "";
    form.value.lastName = props.resident.lastName || "";
    form.value.phone = props.resident.phone || "";
    form.value.email = props.resident.email || "";
  }
});

function returnValue() {
  return null;
}

async function save(): Promise<void> {
  loading.value = true;
  let resident: IResidentDTO;
  if (props.resident !== undefined) {
    resident = await residentService.update(props.resident.id, {
      nationalId: form.value.nationalId,
      firstName: form.value.firstName !== "" ? form.value.firstName : null,
      lastName: form.value.lastName !== "" ? form.value.lastName : null,
      phone: form.value.phone !== "" ? form.value.phone : null,
      email: form.value.email !== "" ? form.value.email : null,
    });
  } else {
    resident = await residentService.create({
      nationalId: form.value.nationalId,
      ...(form.value.firstName !== "" && { firstName: form.value.firstName }),
      ...(form.value.lastName !== "" && { lastName: form.value.lastName }),
      ...(form.value.phone !== "" && { phone: form.value.phone }),
      ...(form.value.email !== "" && { email: form.value.email }),
    });
  }
  loading.value = false;
  $emit("close");
}
</script>
