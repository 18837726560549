<template>
  <layout-component>
    <div class="agreement">
      <button-component
        class="agreement__back"
        :route="{ name: 'admin.agreement' }"
        button-size="sm"
      >
        <i class="fas fa-chevron-left" style="margin-right: 5px" />
        {{ $t("agreement.back") }}
      </button-component>
      <template v-if="agreement || isNew">
        <form @submit.prevent="save">
          <h1 class="agreement__name">
            <input-component
              v-if="isEditMode"
              v-model="editAgreement.name"
              :label="$t('agreement.name')"
              size="xl"
            />
            <template v-else-if="agreement">
              {{ agreement.name }}
            </template>
          </h1>
          <p v-if="!isNew && agreement" class="agreement__company">
            {{ agreement.customerName }}
          </p>
          <div class="agreement__info">
            <table class="agreement__info__contact">
              <tr>
                <th>{{ $t("agreement.from") }}</th>
                <td>
                  <input
                    v-if="isEditMode"
                    v-model="editAgreement.from"
                    type="date"
                    class="agreement__info__contact__input"
                  />
                  <template v-else-if="agreement">
                    {{ agreement.from }}
                  </template>
                </td>
              </tr>
              <tr>
                <th>{{ $t("agreement.to") }}</th>
                <td>
                  <input
                    v-if="isEditMode"
                    v-model="editAgreement.to"
                    type="date"
                    class="agreement__info__contact__input"
                  />
                  <template v-else-if="agreement">
                    {{ agreement.to }}
                  </template>
                </td>
              </tr>
              <tr>
                <th>{{ $t("agreement.customer_name") }}</th>
                <td>
                  <input
                    v-if="isEditMode"
                    v-model="editAgreement.customerName"
                    class="agreement__info__contact__input"
                  />
                  <template v-else-if="agreement">
                    {{ agreement.customerName }}
                  </template>
                </td>
              </tr>
              <tr>
                <th>{{ $t("agreement.customer_number") }}</th>
                <td>
                  <input
                    v-if="isEditMode"
                    v-model="editAgreement.customerNumber"
                    class="agreement__info__contact__input"
                  />
                  <template v-else-if="agreement">
                    {{ agreement.customerNumber }}
                  </template>
                </td>
              </tr>
              <tr>
                <th>{{ $t("agreement.contact_name") }}</th>
                <td>
                  <input
                    v-if="isEditMode"
                    v-model="editAgreement.contactName"
                    class="agreement__info__contact__input"
                  />
                  <template v-else-if="agreement">
                    {{ agreement.contactName }}
                  </template>
                </td>
              </tr>
              <tr>
                <th>{{ $t("agreement.contact_phone") }}</th>
                <td>
                  <input
                    v-if="isEditMode"
                    v-model="editAgreement.contactPhone"
                    class="agreement__info__contact__input"
                  />
                  <template v-else-if="agreement">
                    {{ agreement.contactPhone }}
                  </template>
                </td>
              </tr>
              <tr>
                <th>{{ $t("agreement.contact_email") }}</th>
                <td>
                  <input
                    v-if="isEditMode"
                    v-model="editAgreement.contactEmail"
                    class="agreement__info__contact__input"
                  />
                  <template v-else-if="agreement">
                    <a :href="`mailto:${agreement.contactEmail}`">{{
                      agreement.contactEmail
                    }}</a>
                  </template>
                </td>
              </tr>
            </table>
            <div class="agreement__info__description">
              <template v-if="isEditMode">
                <label class="agreement__info__description__label">{{
                  $t("agreement.description")
                }}</label>
                <tinymce-component
                  v-model="editAgreement.description"
                  style="min-height: 300px"
                />
              </template>
              <div v-else-if="agreement" v-html="agreement.description" />
            </div>
          </div>
          <button-component
            v-if="isEditMode"
            :disabled="!isValid"
            type="submit"
          >
            {{ $t("agreement.save") }}
          </button-component>
          <button-component
            v-else
            key="edit-button"
            type="button"
            @click="edit"
          >
            {{ $t("agreement.edit") }}
          </button-component>
          <button-component
            v-if="!isNew && isEditMode"
            button-style="secondary"
            @click="abortEdit"
          >
            {{ $t("agreement.cancel") }}
          </button-component>
          <button-component
            v-if="!isNew"
            button-style="warning"
            @click="remove"
          >
            {{ $t("agreement.delete") }}
          </button-component>
        </form>
        <div v-if="!isNew && agreement" class="agreement__documents">
          <h2 class="agreement__documents__header">
            {{ $t("agreement.file.files") }}
          </h2>
          <table class="agreement__documents__list">
            <thead>
              <tr>
                <th>{{ $t("agreement.file.name") }}</th>
                <th>{{ $t("agreement.file.last_modified") }}</th>
                <th class="agreement__documents__list__item--right">
                  {{ $t("agreement.file.delete") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(file, index) in agreement.documents"
                :key="`file_${index}`"
              >
                <td>
                  <a :href="file.url" target="_blank">{{ file.name }}</a>
                </td>
                <td>{{ dateFormat(file.lastModified) }}</td>
                <td class="agreement__documents__list__item--right">
                  <button-component
                    button-size="sm"
                    button-type="outlined"
                    @click="deleteFile(file.name)"
                  >
                    <i class="far fa-trash-alt" />
                  </button-component>
                </td>
              </tr>
            </tbody>
          </table>
          <file-upload-component
            :show-list="false"
            :multiple="true"
            @update:modelValue="uploadFile"
          />
        </div>
        <div v-if="!isNew && agreement" class="agreement__comments">
          <h2 class="agreement__comments__header">
            {{ $t("agreement.comment.comments") }}
          </h2>
          <div
            v-for="(comment, index) in agreement.comments"
            :key="`comment_${index}`"
            class="agreement__comments__comment"
          >
            <h5 class="agreement__comments__comment__user">
              <template v-if="comment.user">
                {{ comment.user }}
              </template>
              <template v-else>
                {{ $t("agreement.comment.unknown_user") }}
              </template>
            </h5>
            <p class="agreement__comments__comment__date">
              {{ comment.createdAt }}
            </p>
            <p class="agreement__comments__comment__content">
              {{ comment.comment }}
            </p>

            <i
              v-if="user && comment.user === user.email"
              v-tooltip="$t('agreement.comment.delete')"
              class="agreement__comments__comment__delete fas fa-trash-alt"
              href=""
              @click="deleteComment(comment.id)"
            />
          </div>
          <div class="agreement__comments__add">
            <div class="agreement__comments__add__header">
              <h4>{{ $t("agreement.comment.add.header") }}</h4>
            </div>
            <div class="agreement__comments__add__content">
              <form @submit.prevent="comment">
                <input-component
                  v-model="newComment"
                  type="textarea"
                  label="Hello world"
                  class="agreement__comments__add__content__input"
                  :disabled="loading"
                  @submit="comment"
                />
                <button-component
                  type="submit"
                  :disabled="!isCommentValid || loading"
                >
                  {{ $t("agreement.comment.add.submit") }}
                </button-component>
              </form>
            </div>
          </div>
        </div>
      </template>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { computed, ref, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

// DTO's
import { IAgreementDTO } from "../../../shared/dto/agreement.dto";
import { IUserPayloadDTO } from "../../../shared/dto/user-payload.dto";

// Bootstrap
import {
  agreementService,
  authService,
  documentService,
  modalService,
} from "../bootstrap";

// Components
import ButtonComponent from "./button.component.vue";
import ConfirmComponent from "./confirm.component.vue";
import FileUploadComponent from "./file-upload.component.vue";
import InputComponent from "./input.component.vue";
import LayoutComponent from "./layout.component.vue";
import ProgressComponent from "./progress.component.vue";
import TinymceComponent from "./tinymce.component.vue";

interface IEditAgreement {
  id?: number;
  name: string;
  description: string;
  from: string;
  to: string;
  customerName: string;
  customerNumber: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
}

const $route = useRoute();
const $router = useRouter();

const agreementId = computed<number | "new" | undefined>(() => {
  let id = $route.params.id;
  if (id === undefined) {
    return undefined;
  }

  // Parse id param as string (if array)
  if (id instanceof Array) {
    if (id.length === 0) {
      return undefined;
    }
    id = id[0];
  }

  if (id === "new") {
    return "new";
  }
  return parseInt(id, 10);
});

const isCommentValid = computed<boolean>(() => {
  return newComment.value !== "";
});

const isValid = computed<boolean>(() => {
  return editAgreement.value.name !== "" && editAgreement.value.from !== "";
});

const isEditMode = computed<boolean>(() => {
  return editMode.value === true;
});

const isNew = computed<boolean>(() => {
  return agreementId.value === "new";
});

const user = computed<IUserPayloadDTO | undefined>(() => {
  return authService.user;
});

watch(agreementId, async () => {
  if (isNew.value === false) {
    editMode.value = false;
    await get();
  }
});

const agreement = ref<IAgreementDTO | null>(null);
const editAgreement = ref<IEditAgreement>({
  name: "",
  description: "",
  from: getToday(),
  to: "",
  customerName: "",
  customerNumber: "",
  contactName: "",
  contactPhone: "",
  contactEmail: "",
});

const newComment = ref<string>("");
const loading = ref<boolean>(false);
const editMode = ref<boolean>(isNew.value);

onMounted(() => {
  if (isNew.value === false) {
    get();
  }
});

async function get(): Promise<void> {
  if (agreementId.value !== undefined && agreementId.value !== "new") {
    agreement.value = await agreementService.get(agreementId.value);
  }
}

function edit(): void {
  if (agreement.value === null) {
    return;
  }
  editAgreement.value = {
    id: agreement.value.id,
    name: agreement.value.name,
    description:
      agreement.value.description !== undefined
        ? agreement.value.description
        : "",
    from: agreement.value.from,
    to: agreement.value.to !== undefined ? agreement.value.to : "",
    customerName:
      agreement.value.customerName !== undefined
        ? agreement.value.customerName
        : "",
    customerNumber:
      agreement.value.customerNumber !== undefined
        ? agreement.value.customerNumber
        : "",
    contactName:
      agreement.value.contactName !== undefined
        ? agreement.value.contactName
        : "",
    contactPhone:
      agreement.value.contactPhone !== undefined
        ? agreement.value.contactPhone
        : "",
    contactEmail:
      agreement.value.contactEmail !== undefined
        ? agreement.value.contactEmail
        : "",
  };
}

function abortEdit(): void {
  editMode.value = false;
}

async function save(): Promise<void> {
  if (isValid.value === false) {
    return;
  }
  loading.value = true;
  if (isNew.value === true) {
    const newAgreement: IAgreementDTO = await agreementService.create({
      name: editAgreement.value.name,
      ...(editAgreement.value.description !== ""
        ? { description: editAgreement.value.description }
        : undefined),
      from: editAgreement.value.from,
      ...(editAgreement.value.to !== ""
        ? { to: editAgreement.value.to }
        : undefined),
      ...(editAgreement.value.customerName !== ""
        ? { customerName: editAgreement.value.customerName }
        : undefined),
      ...(editAgreement.value.customerNumber !== ""
        ? { customerNumber: editAgreement.value.customerNumber }
        : undefined),
      ...(editAgreement.value.contactName !== ""
        ? { contactName: editAgreement.value.contactName }
        : undefined),
      ...(editAgreement.value.contactPhone !== ""
        ? { contactPhone: editAgreement.value.contactPhone }
        : undefined),
      ...(editAgreement.value.contactEmail !== ""
        ? { contactEmail: editAgreement.value.contactEmail }
        : undefined),
    });
    $router.push({
      name: "admin.agreement.show",
      params: { id: newAgreement.id.toString() },
    });
  } else {
    await agreementService.update(agreementId.value as number, {
      name: editAgreement.value.name,
      ...(editAgreement.value.description !== ""
        ? { description: editAgreement.value.description }
        : undefined),
      from: editAgreement.value.from,
      ...(editAgreement.value.to !== ""
        ? { to: editAgreement.value.to }
        : undefined),
      ...(editAgreement.value.customerName !== ""
        ? { customerName: editAgreement.value.customerName }
        : undefined),
      ...(editAgreement.value.customerNumber !== ""
        ? { customerNumber: editAgreement.value.customerNumber }
        : undefined),
      ...(editAgreement.value.contactName !== ""
        ? { contactName: editAgreement.value.contactName }
        : undefined),
      ...(editAgreement.value.contactPhone !== ""
        ? { contactPhone: editAgreement.value.contactPhone }
        : undefined),
      ...(editAgreement.value.contactEmail !== ""
        ? { contactEmail: editAgreement.value.contactEmail }
        : undefined),
    });
    await get();
    editMode.value = false;
  }
  loading.value = false;
}

async function remove(): Promise<void> {
  if (agreementId.value === undefined || agreementId.value === "new") {
    return;
  }
  const confirm = window.confirm("Är du säker?");
  if (confirm === true) {
    await agreementService.delete(agreementId.value);
    $router.push({ name: "admin.agreement" });
  }
}

async function uploadFile(files: File[]): Promise<void> {
  if (agreement.value === null) {
    return;
  }

  const modal = modalService.create(ProgressComponent, { progress: 0 });
  modal.show();
  await documentService.uploadFiles(
    "agreement",
    agreement.value.id,
    files,
    undefined,
    (loaded: number, total: number) => {
      modal.setProps({ progress: Math.round((loaded / total) * 100) });
    }
  );
  modal.close();
  await get();
}

async function comment(): Promise<void> {
  if (agreement.value === null) {
    return;
  }
  loading.value = true;
  try {
    await agreementService.comment(agreement.value.id, {
      comment: newComment.value,
    });
    newComment.value = "";
    await get();
  } finally {
    loading.value = false;
  }
}

async function deleteComment(commentId: number): Promise<void> {
  if (agreement.value === null) {
    return;
  }
  const confirm = window.confirm("Är du säker?");
  if (confirm === true) {
    await agreementService.deleteComment(agreement.value.id, commentId);
    await get();
  }
}

async function deleteFile(filename: string): Promise<void> {
  if (agreement.value === null) {
    return;
  }
  const confirm = window.confirm(
    `Är du säker på att du vill ta bort filen "${filename}"`
  );
  if (confirm === true) {
    await documentService.deleteFile("agreement", agreement.value.id, filename);
    await get();
  }
}

function dateFormat(date: string): string {
  return date.substr(0, 16);
}

function getToday(): string {
  const date = new Date();
  return `${date.getFullYear()}-${padLeft(date.getMonth() + 1, 2)}-${padLeft(
    date.getDate(),
    2
  )}`;
}

function padLeft(
  value: string | number,
  length: number,
  filler: string = "0"
): string {
  return `${filler.repeat(
    Math.max(0, length - value.toString().length)
  )}${value}`;
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";
@mixin h2($color: false) {
  padding: 10px 0px;
  margin: 0px;
  font-size: 20px;
  @if ($color) {
    padding: 10px;
    background-color: $green;
    color: #fff;
  }
}
.agreement {
  &__back {
    margin-bottom: 20px;
  }
  &__name {
    font-size: 30px;
  }
  &__company {
    flex-basis: 100%;
  }
  &__info {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    &__contact {
      min-width: 300px;
      @include list(false, "vertical");
      &__input {
        border: none;
        background-color: #eee;
      }
    }
    &__description {
      padding-left: 10px;
      width: 70%;
      height: 100%;
      &__label {
        margin-bottom: 10px;
      }
    }
  }
  &__documents {
    margin-top: 20px;
    &__header {
      @include h2;
    }
    &__list {
      @include list;
      width: 100%;
      &__item {
        &--right {
          text-align: right;
        }
      }
    }
  }
  &__comments {
    margin-top: 30px;
    &__header {
      @include h2(true);
    }
    &__comment {
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &:nth-child(2n) {
        background-color: $gray-400;
      }
      &__user,
      &__date {
        margin: 0px;
        font-size: 14px;
        font-style: italic;
        color: $gray-600;
      }
      &__content {
        margin: 10px 0px 20px;
        flex-basis: 100%;
      }
      &__delete {
        cursor: pointer;
      }
    }
    &__add {
      margin: 20px 30px;
      @include card;
      &__header {
        @include card-header;
        h4 {
          margin: 0px;
          font-size: 17px;
        }
      }
      &__content {
        @include card-body;
      }
    }
  }
}
</style>
