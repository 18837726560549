<template>
  <modal-layout-component :header="label || $t('confirm.label')">
    <div class="confirm">
      <progress-component v-if="loading" />
      <template v-else>
        <div class="confirm__buttons">
          <button-component @click="confirm(true)">
            {{ $t(`confirm.${type}.continue`) }}
          </button-component>
          <button-component button-style="secondary" @click="confirm(false)">
            {{ $t(`confirm.${type}.abort`) }}
          </button-component>
        </div>
      </template>
    </div>
  </modal-layout-component>
</template>
<script lang="ts" setup>
// Libs
import { withDefaults, ref, defineEmits } from "vue";

// Components
import ButtonComponent from "./button.component.vue";
import ProgressComponent from "./progress.component.vue";
import ModalLayoutComponent from "./modal-layout.component.vue";

const props = withDefaults(
  defineProps<{
    label?: string;
    type?: "yesno" | "confirm" | "ok";
    beforeClose?: (action: boolean) => Promise<void>;
  }>(),
  {
    type: "yesno",
  }
);

const $emit = defineEmits(["close"]);

const loading = ref<boolean>(false);

async function confirm(confirm: boolean): Promise<void> {
  loading.value = true;
  if (props.beforeClose) await props.beforeClose(confirm);
  $emit("close", confirm);
}
</script>
<style lang="scss" scoped>
.confirm {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__text {
  }
  &__buttons {
    display: flex;
    gap: 10px;
  }
}
</style>
