<template>
  <layout-component>
    <form v-if="resident" class="resident">
      <h1 class="resident__header">
        Boende
        <button-component
          v-tooltip="'Redigera boende'"
          :route="{ name: 'admin.resident.edit', params: { id: resident.id } }"
          class="resident__header__edit-button"
          button-size="sm"
          button-style="secondary"
        >
          <i class="fa-solid fa-pen-to-square" />
        </button-component>
      </h1>
      <div class="resident__info">
        <table class="resident__info__list">
          <tr>
            <th>Personnummer</th>
            <td>
              {{ resident.nationalId }}
            </td>
          </tr>
          <tr>
            <th>Förnamn</th>
            <td>
              {{ resident.firstName }}
            </td>
          </tr>
          <tr>
            <th>Efternamn</th>
            <td>
              {{ resident.lastName }}
            </td>
          </tr>
          <tr>
            <th>Telefon</th>
            <td>
              <template v-if="resident.phone">
                <a :href="`tel:${resident.phone}`">{{ resident.phone }}</a>
              </template>
            </td>
          </tr>
          <tr>
            <th>E-postadress</th>
            <td>
              <template v-if="resident.email">
                <a :href="`mailto:${resident.email}`">{{ resident.email }}</a>
              </template>
            </td>
          </tr>
          <tr>
            <th>Medlem sedan</th>
            <td>
              {{ memberSince(resident) }}
            </td>
          </tr>
          <template
            v-if="
              resident.currentResidency && resident.currentResidency.apartment
            "
          >
            <tr>
              <td colspan="2">
                Lägenhet (<router-link
                  :to="{
                    name: 'admin.apartment.show',
                    params: { id: resident.currentResidency.apartment.id },
                  }"
                >
                  #{{
                    resident.currentResidency.apartment.serialNumber
                  }} </router-link
                >)
              </td>
            </tr>
            <tr>
              <th>Innehav</th>
              <td>
                <router-link
                  :to="{
                    name: 'admin.residency.show',
                    params: { id: resident.currentResidency.id },
                  }"
                >
                  {{ holdingNumber(resident.currentResidency) }}
                </router-link>
              </td>
            </tr>
            <tr>
              <th>Hus</th>
              <td>{{ resident.currentResidency.apartment.houseNumber }}</td>
            </tr>
            <tr>
              <th>Våning</th>
              <td>{{ resident.currentResidency.apartment.floor }}</td>
            </tr>
            <tr v-if="resident.currentResidency.apartment.apartmentNumber">
              <th>Lgh.</th>
              <td>{{ resident.currentResidency.apartment.apartmentNumber }}</td>
            </tr>
            <tr>
              <th>Rum</th>
              <td>{{ resident.currentResidency.apartment.rooms }}</td>
            </tr>
            <tr>
              <th>Storlek</th>
              <td>{{ resident.currentResidency.apartment.size }}</td>
            </tr>
            <tr v-if="resident.currentResidency.apartment.aptusReference">
              <th>Aptusreferens</th>
              <td>{{ resident.currentResidency.apartment.aptusReference }}</td>
            </tr>
            <tr>
              <th>Upplåtelseform</th>
              <td>
                {{
                  $t(
                    `apartment.intent.${resident.currentResidency.apartment.intent}`
                  )
                }}
              </td>
            </tr>
            <tr>
              <th>Andel</th>
              <td>
                {{ percentage(resident.currentResidency.apartment.share) }}
              </td>
            </tr>
          </template>
        </table>
        <div class="resident__info__files">
          <table class="resident__info__files__list">
            <thead>
              <tr>
                <th>Fil</th>
                <th>Typ</th>
                <th>Uppladdad</th>
                <th
                  class="resident__info__files__list__item resident__info__files__list__item--right"
                >
                  Ta bort
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(document, index) in resident.documents"
                :key="`document_${index}`"
              >
                <td>
                  <a :href="document.url" target="_blank">{{
                    document.name
                  }}</a>
                </td>
                <td>{{ $t(`document.type.${document.type}`) }}</td>
                <td>{{ dateFormat(document.lastModified) }}</td>
                <td
                  class="resident__info__files__list__item resident__info__files__list__item--right"
                >
                  <button-component
                    button-size="xs"
                    button-type="outlined"
                    @click="deleteFile(document.type, document.name)"
                  >
                    <i class="far fa-trash-alt" />
                  </button-component>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="resident__info__files__upload">
            <select-component
              v-model="fileType"
              class="resident__info__files__upload__select"
              label="Ladda upp fil"
              :options="fileTypes"
            />
            <file-upload-component
              :show-list="false"
              :multiple="true"
              @update:modelValue="uploadFile"
            />
          </div>
        </div>
      </div>
      <table class="resident__residencies">
        <thead>
          <tr>
            <th>Innehav</th>
            <th>Hus</th>
            <th>Våning</th>
            <th>Lgh.</th>
            <th>Upplåtelseform</th>
            <th>Från</th>
            <th>Till</th>
          </tr>
        </thead>
        <tbody v-if="resident.residencies">
          <tr
            v-for="(residency, index) in resident.residencies"
            :key="`residency_${index}`"
            class="resident__residencies__row"
          >
            <td>
              <router-link
                class="resident__residencies__row__link"
                :to="{
                  name: 'admin.residency.show',
                  params: { id: residency.id },
                }"
              >
                {{ holdingNumber(residency) }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="residency.apartment"
                class="resident__residencies__row__link"
                :to="{
                  name: 'admin.residency.show',
                  params: { id: residency.id },
                }"
              >
                {{ residency.apartment.houseNumber }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="residency.apartment"
                class="resident__residencies__row__link"
                :to="{
                  name: 'admin.residency.show',
                  params: { id: residency.id },
                }"
              >
                {{ residency.apartment.floor }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="residency.apartment"
                class="resident__residencies__row__link"
                :to="{
                  name: 'admin.residency.show',
                  params: { id: residency.id },
                }"
              >
                {{ residency.apartment.apartmentNumber }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="residency.apartment"
                class="resident__residencies__row__link"
                :to="{
                  name: 'admin.residency.show',
                  params: { id: residency.id },
                }"
              >
                {{ $t(`apartment.intent.${residency.apartment.intent}`) }}
              </router-link>
            </td>
            <td>
              <router-link
                class="resident__residencies__row__link"
                :to="{
                  name: 'admin.residency.show',
                  params: { id: residency.id },
                }"
              >
                {{ residency.from }}
              </router-link>
            </td>
            <td>
              <router-link
                class="resident__residencies__row__link"
                :to="{
                  name: 'admin.residency.show',
                  params: { id: residency.id },
                }"
              >
                <template v-if="residency.end">
                  {{ parseDate(residency.end) }} </template
                ><template v-else> - </template>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { watch, computed, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

// DTO's
import { IResidencyDTO } from "../../../shared/dto/residency.dto";
import { IResidentDTO } from "../../../shared/dto/resident.dto";

// Bootstrap
import { documentService, modalService, residentService } from "../bootstrap";
import { ModalModule } from "../modules/modal.module";

// Components
import ButtonComponent from "./button.component.vue";
import ConfirmComponent from "./confirm.component.vue";
import FileUploadComponent from "./file-upload.component.vue";
import InputComponent from "./input.component.vue";
import LayoutComponent from "./layout.component.vue";
import ProgressComponent from "./progress.component.vue";
import ResidentFormComponent from "./resident-form.component.vue";
import SelectComponent, { IOption } from "./select.component.vue";

const $route = useRoute();
const $router = useRouter();
const $i18n = useI18n();

const resident = ref<IResidentDTO | null>(null);
const fileType = ref<string>("other");
const form = ref({
  nationalId: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
});

const isEdit = computed(() => {
  return $route.name === "admin.resident.edit";
});

const residentId = computed(() => {
  const id = $route.params.id;
  if (id === undefined) {
    return undefined;
  }
  return parseInt(id, 10);
});

watch(isEdit, (value) => {
  if (value === true && residentId.value !== undefined) {
    edit(resident.value);
  } else {
    hideModal();
    get();
  }
});

const fileTypes = computed(() => {
  return [
    {
      value: "credit_score",
      text: $i18n.t("document.type.credit_score").toString(),
    },
    {
      value: "other",
      text: $i18n.t("document.type.other").toString(),
    },
  ];
});

onMounted(async () => {
  await get();
  if (isEdit.value === true && resident.value !== null) {
    edit(resident.value);
  }
});

async function get(): Promise<void> {
  if (residentId.value === undefined) {
    return;
  }

  if (residentId.value === undefined) {
    return;
  }

  resident.value = await residentService.get(residentId.value);
}

let editModal: ModalModule | undefined;

async function edit(res: IResidentDTO | null): Promise<void> {
  if (res === null) {
    return;
  }
  editModal = modalService.create(ResidentFormComponent, {
    resident: res,
  });
  editModal.show();
  editModal.onClose((payload: IResidentDTO | undefined) => {
    if (payload !== undefined) {
      resident.value = payload;
    }
    reRouteToShow(res.id);
  });
  editModal.onHide(() => reRouteToShow(res.id));
}

function hideModal(): void {
  if (editModal !== undefined) {
    editModal.hide();
  }
}

async function reRouteToShow(residentId: number): void {
  await $router.push({
    name: "admin.resident.show",
    params: { id: residentId.toString() },
  });
}

function dateFormat(date: string): string {
  return date.substr(0, 16);
}

function uploadFile(files: File[]): void {
  if (resident.value === null) {
    return;
  }

  const modal = modalService.create(ProgressComponent, { progress: 0 });
  modal.show();
  documentService
    .uploadFiles(
      "resident",
      resident.value.id,
      files,
      fileType.value,
      (loaded: number, total: number) => {
        modal.setProps({ progress: Math.round((loaded / total) * 100) });
      }
    )
    .then(() => {
      modal.close();
      get();
    });
}

async function deleteFile(type: string, filename: string): Promise<void> {
  if (resident.value === null) {
    return;
  }
  modalService
    .asPromise(ConfirmComponent, {
      label: `Är du säker på att du vill ta bort filen "${filename}"`,
      type: "confirm",
    })
    .then((action?: unknown) => {
      if (resident.value !== null && action === true) {
        documentService
          .deleteFile("resident", resident.value.id, filename, type)
          .then(() => get());
      }
    });
}

function holdingNumber(residency: IResidencyDTO): string {
  if (residency.apartment === undefined) {
    throw new Error("Apartment not defined");
  }
  return `${residency.apartment.holdingNumber}:${residency.holdingSuffix}`;
}

function percentage(value: number): string {
  return `${Math.round(value * 100000000) / 1000000}%`;
}

function parseDate(date: string): string {
  return date.substring(0, 10);
}

function memberSince(resident: IResidentDTO): string | undefined {
  if (resident.residencies !== undefined && resident.residencies.length > 0) {
    const residencies = [...resident.residencies];
    return residencies.sort((a: IResidencyDTO, b: IResidencyDTO) =>
      a.from > b.from ? 1 : -1
    )[0].from;
  }
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.resident {
  &__header {
    display: flex;
    align-items: center;
    &__edit-button {
      margin-left: 10px;
    }
  }
  &__info {
    display: flex;
    align-items: flex-start;
    &__list {
      margin-right: 10px;
      width: 50%;
      @include list(false, "vertical");
    }
    &__files {
      width: 100%;
      margin-left: 10px;
      &__list {
        @include list;
        width: 100%;
        &__item {
          &--right {
            text-align: right;
          }
        }
      }
      &__upload {
        margin-top: 20px;
        display: flex;
        &__select {
          margin-right: 20px;
          width: 50%;
        }
      }
    }
  }
  &__residencies {
    @include list(true);
    margin-top: 20px;
    width: 100%;
    &__row {
      &__link {
        text-decoration: none;
        color: #000;
        padding: $list-padding;
        display: block;
      }
    }
  }
}
</style>
