import { RouteLocationRaw, RouteLocation } from "vue-router";

interface IMenu {
  pushRight?: true;
}

export interface IRoute extends IMenu {
  route: RouteLocationRaw;
  name: string;
  root?: string;
  children?: menu[];
}
export interface IDivider extends IMenu {
  divider: true;
}

export type menu = IRoute | IDivider;

export const getMenu = (isAuth: boolean = false): menu[] => [
  { route: { name: "start" }, name: "start" },
  { route: { name: "association.board" }, root: "/association", name: "association.association", children: [
    { route: { name: "association.board" }, name: "association.board" },
    { route: { name: "association.apartments" }, name: "association.apartments" },
    { route: { name: "association.statutes" }, name: "association.statutes" },
    { route: { name: "association.rules" }, name: "association.rules" },
    { route: { name: "association.fire_safety" }, name: "association.fire_safety" },
    { route: { name: "association.policy" }, name: "association.policy" },
    { route: { name: "association.annual_meeting" }, name: "association.annual_meeting" },
    { route: { name: "association.realtor" }, name: "association.realtor" },
  ] },
  { route: { name: "resident.laundry" }, root: "/resident", name: "resident.resident", children: [
    { route: { name: "resident.laundry" }, name: "resident.laundry" },
    { route: { name: "resident.courtyard" }, name: "resident.courtyard" },
    { route: { name: "resident.access_control" }, name: "resident.access_control" },
    { route: { name: "resident.electricity_and_internet" }, name: "resident.electricity_and_internet" },
    { route: { name: "resident.bike_storage" }, name: "resident.bike_storage" },
    { route: { name: "resident.stroller_storage" }, name: "resident.stroller_storage" },
    { route: { name: "resident.storage_room" }, name: "resident.storage_room" },
    { route: { name: "resident.common_room" }, name: "resident.common_room" },
    { route: { name: "resident.parking" }, name: "resident.parking" },
  ] },
  { route: { name: "forms_and_documents.plans" }, name: "forms_and_documents.forms_and_documents", root: "/forms_and_documents", pushRight: true, children: [
    { route: { name: "forms_and_documents.plans" }, name: "forms_and_documents.plans" },
    { route: { name: "forms_and_documents.subletting" }, name: "forms_and_documents.subletting" },
    { route: { name: "forms_and_documents.order_keys" }, name: "forms_and_documents.order_keys" },
    { route: { name: "forms_and_documents.renovation" }, name: "forms_and_documents.renovation" },
    { route: { name: "forms_and_documents.name_change" }, name: "forms_and_documents.name_change" },
  ] },
  { route: { name: "contact.fault_report" }, name: "contact.contact", root: "/contact", pushRight: true, children: [
    { route: { name: "contact.fault_report" }, name: "contact.fault_report" },
    { route: { name: "contact.other" }, name: "contact.other" },
  ] },
  ...(isAuth === true ? [
    { divider: true, pushRight: true },
    { route: { name: "admin.agreement" }, name: "admin.admin", root: "/admin", pushRight: true, children: [
      { route: { name: "admin.apartment" }, name: "admin.apartment" },
      { route: { name: "admin.resident" }, name: "admin.resident" },
      { route: { name: "admin.document" }, name: "admin.document" },
      { route: { name: "admin.sms" }, name: "admin.sms" },
      { route: { name: "admin.agreement" }, name: "admin.agreement" },
    ] },
    { route: { name: "wiki.rule_of_procedures" }, name: "wiki.wiki", root: "/wiki", pushRight: true, children: [
      { route: { name: "wiki.rule_of_procedures" }, name: "wiki.rule_of_procedures" },
    ] },
    { route: { name: "logout" }, name: "logout", pushRight: true }
  ] as menu[] : []),
  // Login link if in local env
  ...(isAuth === false && process.env.ENV === "local" ? [
    { divider: true, pushRight: true },
    { route: { name: "login" }, name: "login", pushRight: true }
  ] as menu[] : [])
];

export const isActive = (currentRoute: RouteLocation, item: IRoute): boolean => {
  if (currentRoute === undefined) {
    return false;
  }

  const currentPath: string = currentRoute.path;
  let compareWith: string = "";
  if(item.root !== undefined) {
    compareWith = item.root;
  } else if(typeof item.route === "string") {
    compareWith = item.route;
  } else if("name" in item.route && item.route.name !== undefined) {
    compareWith = item.route.name.toString();
  }
  return compareWith === currentPath.substring(0, compareWith.length);
};

export function isMenuItemRoute(item: menu): item is IRoute {
  return "route" in item;
}

export function isMenuItemDivider(item: menu): item is IDivider {
  return "divider" in item;
}
