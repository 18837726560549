import { ITextUpdateDTO } from "../../../shared/dto/text-update.dto";
import { ITextDTO } from "../../../shared/dto/text.dto";
import { APIService, IResponse } from "../../../shared/services/api.service";

type indexQuery = {
  relatedUrlOnly?: true,
  limit?: number,
}

export class TextService {
  public constructor(
    private readonly apiService: APIService,
  ) {
    console.log("text service initialized", this);
  }

  public cache: Record<string, ITextDTO> = {};

  public index(query: indexQuery = {}): Promise<ITextDTO[]> {
    return new Promise((resolve, reject) => {
      // Keep track of resolved or not
      let resolved: boolean = false;

      const { relatedUrlOnly, limit } = query;

      /*if(Object.keys(this.cache).length > 0) {
        resolved = true;
        resolve(this.latestFromCatch(limit));
      }*/

      this.apiService.get<ITextDTO[]>("/text", {
        ...(relatedUrlOnly === true ? { relatedUrlOnly: "true" } : undefined),
        ...(limit !== undefined ? { limit: `${limit}` } : undefined)
      }).then((response: IResponse<ITextDTO[]>) => {
        const texts: ITextDTO[] = response.body;

        for(const text of texts) {
          this.cache[text.name] = text;
        }

        if(resolved === false) {
          resolved = true;
          resolve(texts);
        }
      }).catch((error: unknown) => reject(error));
    });
  }
  latestFromCatch(limit?: number): ITextDTO[] {
    const keys = Object.keys(this.cache);
    return keys.map((key: string) => this.cache[key]).sort((a: ITextDTO, b: ITextDTO) => a.lastUpdated < b.lastUpdated ? 1 : -1).slice(0, limit !== undefined ? limit : keys.length);
  }

  public get(name: string, onRefresh?: (text: ITextDTO) => void): Promise<ITextDTO> {
    return new Promise((resolve, reject) => {
      // Keep track of resolved or not
      let resolved: boolean = false;

      // If cache exists
      /*if(this.cache[name] !== undefined) {
        // Resolve cached value
        resolved = true;
        resolve(this.cache[name]);
      }*/

      // Update cache
      this.apiService.get<ITextDTO>(`/text/${name}`, undefined, { limitByName: "text" })
        .then((response: IResponse<ITextDTO>) => {
          const text: ITextDTO = response.body;
          // Save to cache
          this.cache[name] = text;
          if(resolved === false) {
            // Resolve if not resolved already
            resolved = true;
            resolve(text);
          }
          // Trigger manual registered callback
          if(onRefresh !== undefined) {
            onRefresh(text);
          }
        })
        .catch((error: unknown) => reject(error));
    });
  }

  public async update(name: string, content: string): Promise<ITextDTO> {
    const text: ITextDTO = (await this.apiService.put<ITextUpdateDTO, ITextDTO>(`/text/${name}`, { content })).body;
    this.cache[name] = text;
    return text;
  }
}