<template>
  <div ref="root" class="input" :class="{ 'input--warning': warning }">
    <label
      class="input__label"
      :class="{
        'input__label--active': isActive,
        'input__label--not-empty': !empty || placeholder !== '',
        'input__label--gray': disabled,
      }"
      @click="focusInput"
    >
      {{ label }}
      <span v-if="required" style="color: #aa0000">*</span>
    </label>
    <textarea
      v-if="type === 'textarea'"
      :id="id"
      ref="input"
      :class="['input__control', `input__control--${size}`]"
      :name="name"
      :placeholder="placeholder"
      :value="modelValue"
      rows="1"
      :disabled="disabled"
      @input="onInput"
      @focus="active = true"
      @blur="
        active = false;
        $emit('blur', $event);
      "
    />
    <input
      v-else
      :id="id"
      ref="input"
      :class="[
        'input__control',
        `input__control--${size}`,
        { 'input__control--no-label': !hasLabel },
      ]"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      @input="onInput"
      @focus="active = true"
      @blur="
        active = false;
        $emit('blur', $event);
      "
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keydown', $event)"
      @keypress="onKeyPress"
    />
    <small v-if="hasHelp" class="input__help">{{ helpText }}</small>
    <small v-if="warning" class="input__warning">{{ warningText }}</small>
  </div>
</template>
<script lang="ts" setup>
// Libs
import {
  defineEmits,
  withDefaults,
  onMounted,
  onUnmounted,
  computed,
  ref,
} from "vue";

const props = withDefaults(
  defineProps<{
    label?: string;
    modelValue?: string;
    id?: string;
    disabled?: boolean;
    required?: boolean;
    type?: "text" | "textarea" | "password";
    inputMode?:
      | "none"
      | "text"
      | "decimal"
      | "numeric"
      | "tel"
      | "search"
      | "email"
      | "url";
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    name?: string;
    placeholder?: string;
    warning?: boolean;
    warningText?: string;
    helpText?: string;
    filter?: string;
  }>(),
  {
    label: "",
    modelValue: "",
    disabled: false,
    required: false,
    type: "text",
    inputMode: "text",
    size: "md",
    name: "",
    placeholder: "",
    warning: false,
    warningText: "",
    helpText: "",
  }
);

const $emit = defineEmits([
  "update:modelValue",
  "blur",
  "keyup",
  "keydown",
  "keypress",
  "submit",
]);

const active = ref<boolean>(false);
const input = ref<HTMLInputElement | HTMLTextAreaElement | null>(null);
const root = ref<HTMLDivElement | null>(null);

const isActive = computed<boolean>(() => active.value);
const empty = computed<boolean>(
  () =>
    props.modelValue === "" ||
    props.modelValue === null ||
    props.modelValue === undefined
);
const hasHelp = computed<boolean>(() => props.helpText !== "");
const hasLabel = computed<boolean>(() => props.label !== "");

const submitEventListner = (event: KeyboardEvent): void => {
  if (
    event.key === "Enter" &&
    (event.metaKey === true || event.ctrlKey === true)
  ) {
    $emit("submit");
  }
};

onMounted(() => {
  // Listner for focus event on component to set focus on input
  if (root.value !== null) {
    root.value.addEventListener("focus", focusInput);
  }

  if (input.value !== null) {
    input.value.onkeydown = submitEventListner;
  }
});

onUnmounted(() => {
  if (input.value !== null) {
    input.value.onkeydown = null;
  }
});

function onInput(event: Event): void {
  if (props.type === "textarea" && input.value !== null) {
    const textarea = input.value;
    const computedStyle = window.getComputedStyle(textarea);
    textarea.style.height = "inherit";
    textarea.style.height = `${
      textarea.scrollHeight -
      parseInt(computedStyle.getPropertyValue("padding-top"), 10) -
      parseInt(computedStyle.getPropertyValue("padding-bottom"), 10)
    }px`;
  }
  $emit("update:modelValue", (event.target as HTMLInputElement).value);
}

function onKeyPress(event: KeyboardEvent): void {
  if (
    props.filter !== undefined &&
    event.target !== null &&
    new RegExp(props.filter).test(`${event.key}`) === false
  ) {
    event.preventDefault();
    return;
  }

  $emit("keypress", event);
}

function focusInput(): void {
  if (input.value !== null) {
    input.value.focus();
  }
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.input {
  display: flex;
  flex-direction: column;
  &__label {
    position: relative;
    top: 20px;
    font-size: 1rem;
    cursor: text;
    transition: transform 0.2s ease-out, color 0.2s ease-out,
      -webkit-transform 0.2s ease-out;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(12px);
    color: $gray-500;
    &--active {
      color: $primary;
      transform: translateY(-14px) scale(0.8);
      transform-origin: 0 0;
    }
    &--not-empty {
      transform: translateY(-14px) scale(0.8);
      transform-origin: 0 0;
    }
    &--gray {
      color: $gray-500;
    }
  }
  &--warning &__label {
    color: $warning;
  }
  #{selector-unify(&, textarea)}__control {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    min-height: 2rem;
    resize: none;
  }
  &__control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $gray-500;
    border-radius: 0;
    outline: none;
    min-height: 3rem;
    width: 100%;
    margin: 0px;
    padding: 0px;
    box-shadow: none;
    box-sizing: content-box;
    transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
    &:focus {
      border-bottom: 1px solid $primary;
      box-shadow: 0 1px 0 0 $primary;
    }
    &:disabled {
      color: $gray-500;
    }
    &--no-label {
      min-height: 2rem;
    }
    &--xs {
      font-size: 11px;
    }
    &--sm {
      font-size: 13px;
    }
    &--md {
      font-size: 16px;
    }
    &--lg {
      font-size: 22px;
    }
    &--xl {
      font-size: 28px;
    }
  }
  &--warning &__control {
    border-bottom: 1px solid $warning;
    box-shadow: 0 1px 0 0 $warning;
  }
  &__help,
  &__warning {
    line-height: 1.75rem;
  }
  /*&__help {
  }*/
  &__warning {
    color: $warning;
  }
}
</style>
