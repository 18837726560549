<template>
  <div :class="['text', { 'text--center': error }]">
    <div v-if="error" class="text__error">
      <i class="text__error__icon fas fa-tools" />
      <p class="text__error__text">
        <i18n-t keypath="text.error">
          <a href="" @click.prevent="changeLanguage('sv')">{{
            $t("language.swedish")
          }}</a>
        </i18n-t>
      </p>
    </div>
    <template v-else>
      <template v-if="text">
        <div class="text__info">
          <div class="text__author">
            {{ $t("text.factual_owner", { owner: $t(`role.${text.role}`) }) }}
          </div>
          <div class="text__status">
            {{ $t("text.last_synced", { date: dateFormat(text.lastUpdated) }) }}
          </div>
        </div>
        <div v-if="shouldTextBeEditable">
          <tinymce-component
            v-model="text.content"
            :inline="true"
            @save="save"
          />
        </div>
        <div v-else v-html="text.content" />
      </template>
      <text-placeholder-component v-else class="text__placeholder" />
    </template>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { watch, computed, onMounted, onUnmounted, ref } from "vue";
import { CanceledError } from "axios";
import { useI18n } from "vue-i18n";

// DTO's
import { ITextDTO } from "../../../shared/dto/text.dto";

// Shared services
import { ISubscription } from "../../../shared/services/broadcast.service";

// Bootstrap
import {
  authService,
  localeService,
  storageService,
  textService,
} from "../bootstrap";

// Components
import TextPlaceholderComponent from "./text-placeholder.component.vue";
import TinymceComponent from "./tinymce.component.vue";

const $i18n = useI18n();

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

watch(
  () => props.name,
  () => {
    get();
  }
);

const isAuth = computed<boolean>(() => authService.isAuth);
const isEdit = ref<boolean>(storageService.get<boolean>("edit", false));
storageService.on("edit", (edit: boolean) => {
  isEdit.value = edit;
});
const shouldTextBeEditable = computed<boolean>(
  () => isAuth.value && isEdit.value
);

const text = ref<ITextDTO | null>(null);
const error = ref<boolean>(false);

let onLocaleChange: ISubscription | undefined;

watch(
  () => $i18n.locale,
  () => {
    get();
  }
);

onMounted(() => {
  get();
  onLocaleChange = localeService.onChange(() => get());
});

onUnmounted(() => {
  if (onLocaleChange !== undefined) {
    onLocaleChange.unsubscribe();
  }
});

function changeLanguage(language: string): void {
  localeService.changeLanguage(language);
}

async function get(): Promise<void> {
  text.value = null;
  error.value = false;
  try {
    text.value = await textService.get(props.name);
  } catch (requestError: unknown) {
    if (requestError instanceof CanceledError) {
      return;
    }

    if (isAuth.value) {
      text.value = {
        name: props.name,
        content: "<i>Empty<i>",
        role: "chairman",
        lastUpdated: "1990-01-01 00:00:00",
      };
    } else {
      error.value = true;
    }
  }
}

async function save(): Promise<void> {
  if (text.value === null) {
    return;
  }
  try {
    text.value = await textService.update(props.name, text.value.content);
    alert("Saved!");
  } catch {
    alert("Something went wrong");
  }
}

function dateFormat(dateValue: string): string {
  const date = new Date(dateValue.replace(/-/g, "/"));
  return `${date.getFullYear()}-${padLeft(date.getMonth() + 1, 2)}-${padLeft(
    date.getDate(),
    2
  )} - ${padLeft(date.getHours(), 2)}:${padLeft(date.getMinutes(), 2)}`;
}

function padLeft(
  value: string | number,
  length: number,
  filler: string = "0"
): string {
  return `${filler.repeat(
    Math.max(0, length - value.toString().length)
  )}${value}`;
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.text {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  &--center {
    justify-content: center;
  }
  &__error {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__icon {
      font-size: 150px;
      margin-bottom: 40px;
    }
    &__text {
      font-size: 20px;
    }
  }
  &__info {
    padding-bottom: 5px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    color: #777;
    font-style: italic;
    border-bottom: 1px solid #aaa;
  }
  &__placeholder {
    margin-top: 20px;
  }
}
</style>
