// Libs
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType, CanceledError, AxiosProgressEvent, AxiosHeaders, RawAxiosRequestHeaders, RawAxiosResponseHeaders, AxiosResponseHeaders } from "axios";

//Services
import { BroadcastService, ISubscription } from "./broadcast.service";

export type headers = Record<string, string>;

export enum APIError {
  CANCELED = "canceled",
}

export interface IOptions {
  params?: Record<string, string>;
  headers?: headers;
  responseType?: ResponseType;
  limitByName?: string;
  onUploadProgress?: (event: AxiosProgressEvent) => void;
  onDownloadProgress?: (event: AxiosProgressEvent) => void;
}
export interface IOptionsSubmit extends IOptions {
  postType?: "json" | "form";
}


export interface IResponse<T> {
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
  status: number;
  body: T;
}

export interface IResponseError {
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
  status: number;
  code?: string;
}

export interface IAPIService {
  get<T extends Record<string, unknown>>(url: string, params?: Record<string, string>, options?: IOptions): Promise<IResponse<T>>;
  post<T extends Record<string, unknown>, R>(url: string, body: T, options?: IOptionsSubmit): Promise<IResponse<R>>;
  put<T extends Record<string, unknown>, R>(url: string, body: T, options?: IOptionsSubmit): Promise<IResponse<R>>;
  patch<T extends Record<string, unknown>, R>(url: string, body: T, options?: IOptionsSubmit): Promise<IResponse<R>>;
}

export class APIService implements IAPIService {
  public constructor(
    baseUrl: string,
    protected readonly axios: AxiosInstance,
    protected readonly broadcastService: BroadcastService,
  ) {
    axios.defaults.baseURL = baseUrl;
    this.axios.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
      // Intercept headers to request
      if(config.headers !== undefined) {
        config.headers = this.addToAxiosRequestHeaders(config.headers, this.defaultHeaders);
      } else {
        config.headers = this.defaultHeaders;
      }

      return config;
    });

    const responseEmitter = (name: string, response: AxiosResponse<unknown>): void => {
      this.broadcastService.emit<IResponse<unknown>>(name, {
        headers: response.headers,
        status: response.status,
        body: response.data,
      });
    };

    this.axios.interceptors.response.use((response: AxiosResponse<unknown>): AxiosResponse<unknown> => {
      responseEmitter("api.response", response);
      responseEmitter("api.response.success", response);
      return response;
    }, (error: AxiosError) => {
      if (error.response !== undefined) {
        responseEmitter("api.response", error.response);
        this.broadcastService.emit<IResponseError>("api.response.error", {
          headers: error.response.headers,
          status: error.response.status,
          ...(error.response.data !== undefined && (error.response.data as any).code !== undefined ? { code: (error.response.data as any).code } : undefined),
        });
      } else {
        this.broadcastService.emit<IResponseError>("api.error");
      }
      throw error;
    });
  }

  public get baseUrl(): string | undefined {
    return this.axios.defaults.baseURL;
  }

  protected defaultHeaders: RawAxiosRequestHeaders = {};

  public namedRequests: Record<string, AbortController> = {};

  public async get<T = unknown>(url: string, params?: Record<string, string>, options?: IOptions): Promise<IResponse<T>> {
    if (options === undefined) {
      options = {};
    }
    let signal: AbortSignal | undefined;
    if(options.limitByName !== undefined) {
      // Get registered abort controller (if any) and abort request
      const namedRequest: AbortController | undefined = this.namedRequests[options.limitByName];
      if(namedRequest !== undefined) {
        namedRequest.abort();
      }
      
      // Create abort controller and add to named requests
      const controller = new AbortController();
      this.namedRequests[options.limitByName] = controller;
      signal = controller.signal;
    }

    try {
      const response: AxiosResponse<T> = await this.axios.get(url, {
        params,
        // Add signal to be able to abort
        ...(signal !== undefined ? { signal } : undefined),
        ...(options.headers !== undefined ? {headers: options.headers } : undefined),
        ...(options.responseType !== undefined ? { responseType: options.responseType } : undefined)
      });
      return {
        headers: response.headers,
        status: response.status,
        body: response.data,
      };
    } catch (error: unknown) {
      if(error instanceof CanceledError) {
        throw APIError.CANCELED;
      }
      throw error;
    }
  }

  public async head(url: string, params?: Record<string, string>, options?: IOptions): Promise<IResponse<undefined>> {
    if (options === undefined) {
      options = {};
    }
    const response: AxiosResponse<undefined> = await this.axios.head(url, { params, ...(options.headers !== undefined ? {headers: options.headers } : undefined), ...(options.responseType !== undefined ? { responseType: options.responseType } : undefined) });
    return {
      headers: response.headers,
      status: response.status,
      body: undefined,
    };
  }

  public async post<T = unknown, R = unknown>(url: string, body: T, options?: IOptionsSubmit): Promise<IResponse<R>> {
    return this.submitData<T, R>("post", url, body, options);
  }

  public async put<T = unknown, R = unknown>(url: string, body?: T, options?: IOptionsSubmit): Promise<IResponse<R>> {
    return this.submitData<T, R>("put", url, body, options);
  }

  public async patch<T = unknown, R = unknown>(url: string, body?: T, options?: IOptionsSubmit): Promise<IResponse<R>> {
    return this.submitData<T, R>("patch", url, body, options);
  }

  public async delete<T = unknown>(url: string, body?: T, options?: IOptions): Promise<void> {
    if (options === undefined) {
      options = {};
    }
    await this.axios.delete<void>(url, { params: options.params, headers: options.headers, ...(options.responseType !== undefined ? { responseType: options.responseType } : undefined) });
  }

  public setBaseUrl(baseUrl: string): void {
    this.axios.defaults.baseURL = baseUrl;
  }

  public setDefaultHeaders(headers: headers): void {
    this.defaultHeaders = { ...this.defaultHeaders, ...headers };
  }

  public removeDefaultHeaders(name: string | string[]): void {
    if (!(name instanceof Array)) { name = [name]; }
    this.defaultHeaders = Object.keys(this.defaultHeaders)
      .filter((key: string) => name.indexOf(key) === -1) // Filter out keys to remove
      .reduce((previousValue: RawAxiosRequestHeaders, key: string) => ({ // Convert array to dictionary
        ...previousValue, // Concat previous value
        [key]: this.defaultHeaders[key], // Add value from headers using array value as key
      }), {});
  }
  
  public hasHeader(name: string): boolean {
    return this.defaultHeaders[name] !== undefined;
  }

  public onResponse<T>(callback: (response: IResponse<T>) => void): ISubscription {
    return this.broadcastService.subscribe<IResponse<T>>("api.response.success", (payload?: IResponse<T>) => {
      if (payload !== undefined) {
        callback(payload);
      }
    });
  }

  public onResponseError(callback: (response: IResponseError) => void): ISubscription {
    return this.broadcastService.subscribe<IResponseError>("api.response.error", (payload?: IResponseError) => {
      if (payload !== undefined) {
        callback(payload);
      }
    });
  }

  public onResponseStatus<T>(status: number, callback: (response: IResponse<T>) => void): ISubscription {
    return this.broadcastService.subscribe<IResponse<T>>("api.response", (payload?: IResponse<T>) => {
      if(payload?.status === status) {
        if (payload !== undefined) {
          callback(payload);
        }
      }
    });
  }

  public onError(callback: () => void): ISubscription {
    return this.broadcastService.subscribe("api.error", () => callback());
  }

  public parseToQuery(object: Record<string, string | string[]>): string {
    const query = Object.keys(object).map((key: string) => {
      let value = object[key];
      if (!(value instanceof Array)) {
        value = [value];
      }
      return value.map((value: string) => `${key}=${value}`).join("&");
    }).join("&");
    return `${query !== "" ? "?" : ""}${query}`;
  }

  public parseFromQuery(query: string): Record<string, string[]> {
    const match = query.match(/^(?:[^?]*)\??([^#]*)/);
    if (match !== null && match[1].length > 0) {
      return match[1].split("&").reduce((previousValue: Record<string, string[]>, currentValue: string) => {
        const split = currentValue.split("=");
        return {
          ...previousValue, [split[0]]: [
            ...(previousValue[split[0]] !== undefined ? previousValue[split[0]] : []),
            ...(split[1] !== undefined ? [split[1]] : []),
          ],
        };
      }, {});
    }
    return {};
  }

  protected async submitData<T, R>(verb: "post" | "put" | "patch", url: string, data?: T, options?: IOptionsSubmit): Promise<IResponse<R>> {
    if (options === undefined) {
      options = {};
    }
    let postData: unknown;
    switch (options.postType) {
      case "form":
        options.headers = { "Content-Type": "application/x-www-form-urlencoded", ...options.headers };
        postData = this.queryStringify(data as unknown as Record<string, string | number | boolean | string[] | number[] | boolean[] | null> | undefined);
        break;
      case "json":
        options.headers = { "Content-Type": "application/json", ...options.headers };
        break;
      default:
        postData = data;
    }

    const response: AxiosResponse<R> = await this.axios[verb](url, postData, {
      params: options.params,
      headers: options.headers,
      ...(options.responseType !== undefined ? { responseType: options.responseType } : undefined),
      ...(options.onUploadProgress !== undefined ? { onUploadProgress: options.onUploadProgress } : undefined),
      ...(options.onDownloadProgress !== undefined ? { onDownloadProgress: options.onDownloadProgress } : undefined),
    });
    return {
      headers: response.headers,
      status: response.status,
      body: response.data,
    };
  }

  protected queryStringify(obj: Record<string, string | number | boolean | null | (string | number | boolean | null)[]> = {}): string {
    return Object.keys(obj).reduce((previousValue: string[], key: string) => {
      const value = obj[key];
      const values = value instanceof Array ? value : [value];
      return [
        ...previousValue,
        ...values.map((value: string | number | boolean | null) => `${key}=${value !== null ? value.toString() : ""}`),
      ];
    }, []).join("&");
  }

  private addToAxiosRequestHeaders(axiosHeaders: RawAxiosRequestHeaders | AxiosHeaders, newHeaders: RawAxiosRequestHeaders): RawAxiosRequestHeaders | AxiosHeaders {
    if(axiosHeaders instanceof AxiosHeaders) {
      for(const key in newHeaders) {
        axiosHeaders.set(key, newHeaders[key]);
      }
      return axiosHeaders;
    } else {
      
      return { ...axiosHeaders, ...newHeaders };
    }
  }
}
