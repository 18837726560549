<template>
  <layout-component>
    <div class="residents">
      <div class="residents__header">
        <h1>{{ $t("resident.resident") }}</h1>
        <checkbox-component
          id="showOnlyActiveResidents"
          v-model="showOnlyActiveResidents"
          class="residents__header__filter"
        >
          {{ $t("resident.filter_only_active") }}
        </checkbox-component>
      </div>
      <table class="residents__list">
        <thead class="residents__list__header">
          <tr>
            <th
              v-for="(column, index) in columns"
              :key="`column_${index}`"
              class="residents__list__header__column"
            >
              <router-link
                class="residents__list__link residents__list__link--header"
                :to="{
                  name: 'admin.resident',
                  query: {
                    'order-by': column,
                    ...(orderBy === column && desc === false
                      ? { desc: 'true' }
                      : undefined),
                  },
                }"
              >
                {{ $t(`resident.list_header.${column}`) }}
                <i
                  v-if="orderBy === column"
                  :class="[
                    'residents__list__link__chevron',
                    'fas',
                    `fa-chevron-${desc === false ? 'down' : 'up'}`,
                  ]"
                />
                <i v-else class="residents__list__link__no-chevron" />
              </router-link>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(resident, index) in residentView"
            :key="`resident_${index}`"
            class="residents__list__row"
          >
            <td
              v-for="(column, columnIndex) in columns"
              :key="`resident_${index}_column_${columnIndex}`"
            >
              <router-link
                class="residents__list__link"
                :to="{
                  name: 'admin.resident.show',
                  params: { id: resident.id },
                }"
              >
                {{ resident[column] }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { watch, computed, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

// DT's
import { IResidentDTO } from "../../../shared/dto/resident.dto";

// Bootstrap
import { residentService } from "../bootstrap";

// Components
import CheckboxComponent from "./checkbox.component.vue";
import LayoutComponent from "./layout.component.vue";

interface IResidentViewModel {
  id: number;
  nationalId: string;
  firstName: string;
  apartmentNumber: string;
  intent: string;
  phone: string;
  email: string;
}

const $route = useRoute();
const { t: $t } = useI18n();

const getQueryOrderBy = (): string | undefined => {
  const orderBy = $route.query["order-by"];
  return typeof orderBy === "string" ? orderBy : undefined;
};

const desc = computed(() => {
  const desc = $route.query["desc"];
  return (typeof desc === "string" && desc === "true") ||
    (typeof desc === "boolean" && (desc as unknown as boolean) === true)
    ? true
    : false;
});

const orderBy = computed(() => {
  return getQueryOrderBy() || "apartment";
});

const residentView = computed(() => {
  return showOnlyActiveResidents.value
    ? residents.value.filter((resident) => resident.intent !== "")
    : residents.value;
});

const residents = ref<IResidentViewModel[]>([]);
const showOnlyActiveResidents = ref<boolean>(true);
const columns = ref<string[]>([
  "nationalId",
  "firstName",
  "lastName",
  "apartmentNumber",
  "intent",
]);

watch(orderBy, () => {
  sortResidents();
});
watch(desc, () => {
  sortResidents();
});

onMounted(() => {
  get();
});

async function get(): Promise<void> {
  residents.value = (await residentService.index()).map(
    (resident: IResidentDTO) => {
      return {
        id: resident.id,
        nationalId: resident.nationalId,
        firstName: resident.firstName !== undefined ? resident.firstName : "",
        lastName: resident.lastName !== undefined ? resident.lastName : "",
        ...(resident.currentResidency !== undefined &&
        resident.currentResidency.apartment !== undefined
          ? {
              apartmentNumber: `${resident.currentResidency.apartment.houseNumber}:${resident.currentResidency.apartment.apartmentNumber}`,
              intent: $t(
                `residency.type.${resident.currentResidency.apartment.intent}`
              ).toString(),
            }
          : {
              apartmentNumber: "",
              intent: "",
            }),
        phone: resident.phone !== undefined ? resident.phone : "",
        email: resident.email !== undefined ? resident.email : "",
      };
    }
  );
  sortResidents();
}

function sortResidents(): void {
  const residentsCopy = [...residents.value];
  residentsCopy.sort((a: IResidentViewModel, b: IResidentViewModel) => {
    const key: string = orderBy.value;
    if (key in a && key in b) {
      const valueA: string = (a as unknown as Record<string, string>)[key];
      const valueB: string = (b as unknown as Record<string, string>)[key];
      return valueA > valueB ? 1 : -1;
    }

    return 0;
  });
  if (desc.value) {
    residentsCopy.reverse();
  }
  residents.value = [...residentsCopy];
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.residents {
  &__header {
    display: flex;
    align-items: center;
    &__filter {
      margin-left: 10px;
    }
  }
  &__list {
    @include list(true);
    margin-top: 10px;
    width: 100%;
    &__header {
      position: sticky;
      top: $menu-height + $main-padding;
      &::before {
        content: "&nbsp;";
        height: 10px;
        width: 100%;
        font-size: 0px;
        background-color: #fff;
        position: absolute;
        top: -10px;
      }
      &__column {
        padding: 0px !important;
      }
    }
    &__link {
      text-decoration: none;
      color: #000;
      padding: $list-padding;
      display: block;
      &--header {
        color: $list-header-color;
      }
      &__no-chevron {
        width: 19px;
        height: 1px;
        display: block;
        float: right;
      }
      &__chevron {
        margin-left: 2px;
      }
    }
  }
}
</style>
