<template>
  <router-link v-if="route" :class="buttonClass" :to="route">
    <div style="position: relative; width: 100%">
      <div
        v-if="loading"
        style="position: absolute; top: 0px; left: 0px; width: 100%"
      >
        <slot name="loader">
          <i class="fas fa-spinner fa-pulse" />
        </slot>
      </div>
    </div>
    <span :style="{ visibility: loading ? 'hidden' : 'visible' }">
      <slot />
    </span>
  </router-link>
  <button
    v-else
    :class="buttonClass"
    :type="type"
    :disabled="disabled"
    @click="() => $emit('click')"
  >
    <div style="position: relative; width: 100%">
      <div
        v-if="loading"
        style="position: absolute; top: 0px; left: 0px; width: 100%"
      >
        <slot name="loader">
          <i class="fas fa-spinner fa-pulse" />
        </slot>
      </div>
    </div>
    <span :style="{ visibility: loading ? 'hidden' : 'visible' }">
      <slot />
    </span>
  </button>
</template>
<script lang="ts" setup>
// Libs
import { RouteLocationRaw } from "vue-router";
import { withDefaults, defineEmits, computed } from "vue";

const props = withDefaults(
  defineProps<{
    loading?: boolean;
    disabled?: boolean;
    route?: RouteLocationRaw;
    type?: "button" | "submit" | "reset";
    buttonType?: "contained" | "outlined" | "text";
    buttonStyle?: "primary" | "secondary" | "info" | "warning" | "error";
    buttonSize?: "xs" | "sm" | "md" | "lg";
  }>(),
  {
    loading: false,
    disabled: false,
    type: "button",
    buttonType: "contained",
    buttonStyle: "primary",
    buttonSize: "md",
  }
);

const $emit = defineEmits(["click"]);

const buttonClass = computed<string>(
  () =>
    `button button--${props.buttonSize} button--${props.buttonType} button--${props.buttonStyle}`
);
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.button {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
  background-color: transparent;
  border-radius: 2px;
  border: 0px;
  transition: 0.3s;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  &:disabled {
    pointer-events: none;
    box-shadow: none;
    color: $gray-500 !important;
    cursor: default;
  }

  &:not(&--text) {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    &:hover {
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14),
        0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
    }
  }

  &--xs {
    font-size: 9px;
    min-height: 18px;
    padding: 0px 6px;
  }
  &--sm {
    font-size: 11px;
    min-height: 25px;
    padding: 0px 10px;
  }
  &--md {
    min-height: 36px;
    padding: 0px 16px;
  }
  &--lg {
    font-size: 1.4rem;
    min-height: 36px;
    padding: 10px 19px;
  }
  &--primary {
  }
  &--secondary {
    &:hover {
      background: theme-color-level("primary", -1.5);
      border-color: transparent;
    }
  }
  &--info {
  }
  &--warning {
  }
  &--error {
  }
  &--contained:disabled {
    background-color: $gray-300 !important;
  }
  &--contained#{&}--primary {
    color: tint-color($primary, 100%);
    background-color: $primary;
  }
  &--contained#{&}--secondary {
    color: tint-color($secondary, 100%);
    background-color: $secondary;
  }
  &--contained#{&}--info {
    color: tint-color($info, 100%);
    background-color: $info;
  }
  &--contained#{&}--warning {
    color: tint-color($warning, 100%);
    background-color: $warning;
  }
  &--contained#{&}--error {
    color: tint-color($danger, 100%);
    background-color: $danger;
  }
  &--outlined {
    background-color: $white;
  }
  &--outlined#{&}--secondary {
    color: $secondary;
  }
  &--outlined#{&}--info,
  &--text#{&}--info {
    color: shade-color($info, 20%);
  }
  &--outlined#{&}--warning,
  &--text#{&}--warning {
    color: shade-color($warning, 20%);
  }
  &--outlined#{&}--error,
  &--text#{&}--error {
    color: shade-color($danger, 20%);
  }
  &--text#{&}--secondary {
    color: $secondary;
  }
}
</style>
