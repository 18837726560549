<template>
  <layout-component>
    <div class="login">
      <div v-if="!isLocal" class="login__social">
        <button-component
          class="login__social__button"
          type="button"
          @click="loginWithGoogle"
        >
          <div class="login__social__button__content">
            <img
              class="login__social__button__image"
              src="../../resources/img/200px-Google__G__Logo.svg.webp.png"
            />
            Logga in med google
          </div>
        </button-component>
      </div>
      <form v-else class="login__local" @submit.prevent="login">
        <input-component v-model="form.email" label="E-post" />
        <input-component
          v-model="form.password"
          label="Lösenord"
          type="password"
        />
        <button-component type="submit"> Logga in </button-component>
      </form>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { onMounted, reactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { authService } from "../bootstrap";

// Components
import ButtonComponent from "./button.component.vue";
import InputComponent from "./input.component.vue";
import LayoutComponent from "./layout.component.vue";

const $route = useRoute();
const $router = useRouter();

const isLocal = computed<boolean>(() => process.env.ENV == "local");

const codeParam = computed<string | undefined>(() => {
  const code = $route.query["code"];
  return typeof code === "string" ? code : undefined;
});

const form = reactive({
  email: "",
  password: "",
});

async function login(): Promise<void> {
  await authService.login(form.email, form.password);
  $router.push("/start");
}

onMounted(async () => {
  if (isLocal) {
    form.email = "test@test.test";
    form.password = "test";
  }

  if (codeParam.value !== undefined) {
    await loginWithGoogleCode(codeParam.value);
  }
});

async function loginWithGoogle(): Promise<void> {
  window.location.href = await authService.getGoogleLoginUrl();
}

async function loginWithGoogleCode(code: string): Promise<void> {
  await authService.loginWithGoogleCode(code);
  $router.push("/start");
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";
.login {
  @include container(true);
  margin-top: 50px;
  &__social {
    display: flex;
    justify-content: center;
    &__button {
      &__content {
        display: flex;
        align-items: center;
      }
      &__image {
        margin-right: 10px;
        height: 28px;
      }
    }
  }
  &__local {
    margin-top: 20px;
    justify-content: center;
  }
}
</style>
