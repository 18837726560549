// Libs
import Vue from "vue";
import { NavigationGuard, NavigationGuardNext, RouteLocationRaw, RouteLocation, RouteLocationNormalized } from "vue-router";
import { AuthService } from "./services/auth.service";

export type navigationGuardNext = (to?: RouteLocationRaw | false | ((vm: Vue) => unknown) | void) => void;

export const auth = (authService: AuthService): NavigationGuard => (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void => {
  if(authService.isAuth === true) {
    next();
    return;
  }
  next({ name: "login", query: { redirectTo: to.fullPath } });
};

export const guest = (authService: AuthService): NavigationGuard => (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void => {
  if(authService.isAuth === false) {
    next();
    return;  
  }

  next({ path: "/error/404" });
};

export const logout = (authService: AuthService): NavigationGuard => (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
  authService.logout();
  next({ name: "start" });
};

// Middleware for admin users
export const errorCode: NavigationGuard = (to, from, next) => {
  const errorCode = parseInt(to.params.code.toString(), 10);
  if(errorCode < 400 || errorCode > 499) {
    next({ path: "/error/404" });
    return;
  }
  next();
};

// Redirect to 404 page if route is not defined
export const invalidRoute = (redirectTo?: RouteLocationRaw): NavigationGuard => (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void => {
  if (to.name) {
    next();
  } else {
    next(redirectTo !== undefined ? redirectTo : { path: "error/404" });
  }
};

export const guard = (guards: NavigationGuard[]) => (from: RouteLocation, to: RouteLocation, next: navigationGuardNext): void => {
  operate(guards, from, to, next, 0);
};

const operate = (guards: NavigationGuard[], from: RouteLocation, to: RouteLocation, lastNext: navigationGuardNext, i: number): void => {
  const guard: NavigationGuard = guards[i];
  if (guards.length === i + 1) {
    guard(from, to, lastNext);
  } else {
    guard(from, to, (nextArg) => {
      switch (typeof (nextArg)) {
        case "undefined":
          operate(guards, from, to, lastNext, i + 1);
          break;
        case "object":
          lastNext(nextArg);
          break;
        case "boolean":
          lastNext(nextArg);
          break;
        case "string":
          lastNext(nextArg);
          break;
      }
    });
  }
};
