<template>
  <div class="text-placeholder">
    <div class="text-placeholder__item text-placeholder__item--image" />
    <div class="text-placeholder__item text-placeholder__item--text text-placeholder__item--first-row" />
    <div class="text-placeholder__item text-placeholder__item--text" />
    <div class="text-placeholder__item text-placeholder__item--text" />
    <div class="text-placeholder__item text-placeholder__item--text text-placeholder__item--end-paragraph" />
    <div class="text-placeholder__item text-placeholder__item--text text-placeholder__item--short-row" />
    <div class="text-placeholder__item text-placeholder__item--text" />
    <div class="text-placeholder__item text-placeholder__item--text" />
  </div>
</template>
<script lang="ts" setup>

</script>
<style lang="scss" scoped>
.text-placeholder {
  &__item {
    &--text {
      height: 20px;
    }
    &--image {
      height: 160px;
      width: 160px;
      margin-right: 15px;
      float:left;
    }
    &--first-row {
      margin-right: 400px;
    }
    &--short-row {
      margin-right: 250px;
    }
      margin-bottom: 15px;
    &--end-paragraph {
      margin-bottom: 60px;
    }
    overflow: hidden;
    background: #000;
    position: relative;
    
    // Animation
    animation-duration: 1.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeholderAnimate;
    background: #f6f7f8; // Fallback
    background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
    background-size: 1300px; // Animation Area
  }
}
@keyframes placeholderAnimate {
    0%{ background-position: -650px 0; }
    100%{ background-position: 650px 0; }
}
</style>