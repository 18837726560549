<template>
  <div :class="['menu', { 'menu--open': isOpen }]">
    <div class="menu__container">
      <div class="menu__toggle">
        <a class="menu__toggle__link" href="" @click.prevent="toggle">
          <i
            v-observe-visibility="toggleVisibilityChanged"
            :class="[
              'fas fa-bars menu__toggle__link__hamburger',
              { 'menu__toggle__hamburger--open': isOpen },
            ]"
          />
          {{ $t("title") }}
        </a>
        <div class="menu__toggle__locale">
          <a href="" @click.prevent="changeLanguage('en')">
            <img src="../../resources/img/gb.png" alt="gb"
          /></a>

          <a href="" @click.prevent="changeLanguage('sv')">
            <img src="../../resources/img/se.png" alt="se"
          /></a>
        </div>
      </div>
      <template v-for="(menu, menuIndex) in menus" :key="`menu_${menuIndex}`">
        <ul class="menu__list">
          <li
            v-for="(item, index) in menu"
            :key="`menu_${menuIndex}_${index}`"
            :class="[
              'menu__list__item',
              { 'menu__list__item--divider': isMenuItemDivider(item) },
            ]"
          >
            <router-link
              v-if="isMenuItemRoute(item)"
              :to="item.route"
              active-class="menu__list__item__link--active"
              :class="[
                'menu__list__item__link',
                { 'menu__list__item__link--active': isActive(item) },
              ]"
              event=""
              @click.native.prevent="itemClick(item, menuIndex, index)"
            >
              {{ $t(`menu.${item.name}`) }}
              <i
                v-if="hasChildren(item)"
                :class="[
                  'fas fa-caret-down menu__list__item__toggle',
                  { 'menu__list__item__toggle--open': shouldBeOpen(item) },
                ]"
              />
            </router-link>
            <height-transition-component :duration="0.1">
              <ul
                v-if="
                  isMenuItemRoute(item) &&
                  hasChildren(item) &&
                  shouldBeOpen(item)
                "
                class="menu__list__item__child-list"
              >
                <li
                  v-for="(child, childIndex) in item.children"
                  :key="`menu_${menuIndex}_${index}_child_${childIndex}`"
                  :class="[
                    'menu__list__item__child-list__item',
                    {
                      'menu__list__item__child-list__item--divider':
                        isMenuItemDivider(child),
                    },
                  ]"
                >
                  <router-link
                    v-if="isMenuItemRoute(child)"
                    :to="child.route"
                    active-class="menu__list__item__child-list__item__link--active"
                    class="menu__list__item__child-list__item__link"
                  >
                    {{ $t(`menu.${child.name}`) }}
                  </router-link>
                </li>
              </ul>
            </height-transition-component>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { useRoute, useRouter, RouteLocationRaw } from "vue-router";
import { ref, watch, computed, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";

// Shared services
import { ISubscription } from "../../../shared/services/broadcast.service";

// Menu
import {
  menu as baseMenu,
  IRoute as IBaseRoute,
  IDivider,
  isActive as isMenuActive,
  getMenu as baseGetMenu,
  isMenuItemRoute,
  isMenuItemDivider,
} from "../menu";

// Bootstrap
import { authService, localeService } from "../bootstrap";

// Components
import heightTransitionComponent from "./height-transition.component.vue";

interface IRoute extends IBaseRoute {
  active: boolean;
  open: boolean;
}
type menu = IRoute | IDivider;

const menu = ref<menu[]>([]);
const open = ref<boolean>(false);
const desktop = ref<boolean>(true);

const $route = useRoute();
const $router = useRouter();
const $i18n = useI18n();

watch($route, () => {
  menu.value = getMenu();
  // Close menu on any route changes
  open.value = false;
});

const menus = computed<menu[][]>(() => [
  menu.value.filter((menu: menu) => menu.pushRight === undefined),
  menu.value.filter((menu: menu) => menu.pushRight === true),
]);

const isOpen = computed<boolean>(() => open.value);
const isDesktop = computed<boolean>(() => desktop.value);

let onLoginOrLogoutSubscription: ISubscription | undefined;

onMounted(() => {
  menu.value = getMenu();
  onLoginOrLogoutSubscription = authService.onLoginOrLogout(() => {
    menu.value = getMenu();
  });
});

onUnmounted(() => {
  if (onLoginOrLogoutSubscription !== undefined) {
    onLoginOrLogoutSubscription.unsubscribe();
  }
});

function changeLanguage(language: string): void {
  localeService.changeLanguage(language);
}

function toggle(): void {
  open.value = !open.value;
}

function hasChildren(item: menu): boolean {
  return (
    "route" in item && item.children !== undefined && item.children.length > 0
  );
}
function shouldBeOpen(item: menu): boolean {
  return "route" in item && (item.active || item.open);
}

function isActive(item: IRoute): boolean {
  return isMenuActive($route, item);
}

function itemClick(item: IRoute, menuIndex: number, index: number): void {
  if (menuIndex > 0) {
    index = menus.value[menuIndex - 1].length + index;
  }
  // Make sure menu is open when clicking on desktop
  if (isDesktop.value === true) {
    openMenu(index);
    navigate(item.route);
    return;
  }

  // Navigate to route if open or don't have children
  if (shouldBeOpen(item) || hasChildren(item) === false) {
    openMenu(index);
    navigate(item.route);
    return;
  }

  // Not open: close all other and open current
  openMenu(index);
}

function toggleVisibilityChanged(isVisible: boolean): void {
  desktop.value = !isVisible;
}

function getMenu(): menu[] {
  return baseGetMenu(authService.isAuth).map((menu: baseMenu) => ({
    ...("route" in menu
      ? {
          ...menu,
          active: isMenuActive($route, menu),
          open: false,
        }
      : { ...menu }),
  }));
}

function openMenu(index: number): void {
  menu.value = menu.value.map((menu: menu, currentIndex: number) => ({
    ...("route" in menu
      ? {
          ...menu,
          active: false,
          open: currentIndex === index,
        }
      : { ...menu }),
  }));
}

function navigate(route: RouteLocationRaw): void {
  let isSame: boolean = false;
  if (
    $route.name !== undefined &&
    typeof route !== "string" &&
    "name" in route &&
    route.name !== undefined &&
    $route.name === route.name
  ) {
    isSame = true;
  }
  if (
    (typeof route === "string" && $route.path === route) ||
    (typeof route !== "string" &&
      "path" in route &&
      route.path !== undefined &&
      $route.path === route.path)
  ) {
    isSame = true;
  }

  open.value = false;

  if (isSame === false) {
    $router.push(route);
  }
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

@mixin list {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
@mixin link {
  padding: 0px 15px;
  height: $menu-height;
  text-transform: uppercase;
  text-decoration: none;
  color: $primary-text;
  display: flex;
  align-items: center;
}

.menu {
  width: 100%;
  min-height: $menu-height;
  background-color: $primary;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  @include mobile {
    height: $menu-height;
    position: fixed;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    &--open {
      height: 100vh;
      z-index: 1000;
    }
  }
  &__container {
    @include desktop {
      @include container;
      display: flex;
      justify-content: space-between;
    }
  }
  &__toggle {
    padding: 0px 15px;
    height: $menu-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include desktop {
      display: none;
    }
    &__link {
      font-size: $font-size-base * 1.2;
      color: $primary-text;
      text-decoration: none;
      &__hamburger {
        margin-right: 15px;
        transition: transform 0.12s ease-in;
        &--open {
          transform: rotate(90deg);
        }
      }
    }
  }
  &__list {
    @include list;
    min-height: $menu-height;
    @include desktop {
      display: flex;
    }
    &__item {
      display: block;
      &--divider {
        border: 1px solid #fff;
        @include mobile {
          height: 1px;
        }
      }
      &__toggle {
        @include desktop {
          display: none;
        }
        margin-left: 10px;
        transition: transform 0.2s;
        &--open {
          transform: rotate(180deg);
        }
      }
      &__link {
        @include link;
        &--active,
        &:hover {
          background-color: darken($primary, 10%);
        }
      }
      &__child-list {
        @include desktop {
          display: none;
        }
        @include list;
        &__item {
          border-bottom: 1px solid #ddd;
          &__link {
            @include link;
            background-color: #fff;
            color: #000;
            &--active,
            &:hover {
              background-color: darken(#fff, 10%);
            }
          }
          &:last-child {
            border-bottom: 0px;
          }
          &--divider {
            border: 1px solid darken($primary, 10%);
          }
        }
      }
    }
  }
}
</style>
