<template>
  <transition
    name="height-transition"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot />
  </transition>
</template>
<script lang="ts" setup>
/**
 * Credit for component goes to https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
 * and https://vuejsdevelopers.com/2018/02/26/vue-js-reusable-transitions/
 */

// Libs
import { withDefaults } from "vue";

withDefaults(
  defineProps<{
    duration?: number;
    timing?: string;
  }>(),
  {
    duration: 1,
    timing: "ease-in-out",
  }
);

function beforeEnter(element: HTMLElement): void {
  setDuration(element);
}

function enter(element: HTMLElement): void {
  const width = getComputedStyle(element).width;

  element.style.setProperty("width", width);
  element.style.setProperty("position", "absolute");
  element.style.setProperty("visibility", "hidden");
  element.style.setProperty("height", "auto");

  const height = getComputedStyle(element).height;

  element.style.removeProperty("width");
  element.style.removeProperty("position");
  element.style.removeProperty("visibility");
  element.style.removeProperty("height");
  element.style.setProperty("height", "0px");

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height;

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = height;
  });
}

function afterEnter(element: HTMLElement): void {
  element.style.setProperty("height", "auto");
  cleanUpDuration(element);
}

function beforeLeave(element: HTMLElement): void {
  setDuration(element);
}

function leave(element: HTMLElement): void {
  const height = getComputedStyle(element).height;

  element.style.setProperty("height", height);

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.setProperty("height", "0px");
  });
}

function afterLeave(element: HTMLElement): void {
  cleanUpDuration(element);
}

function setDuration(element: HTMLElement): void {
  element.style.setProperty("transition-duration", `${this.duration}s`);
  element.style.setProperty("transition-Timing-function", this.timing);
}

function cleanUpDuration(element: HTMLElement): void {
  element.style.removeProperty("transition-duration");
  element.style.removeProperty("transition-Timing-function");
}
</script>
<style lang="scss" scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.height-transition {
  &-enter-active,
  &-leave-active {
    transition: height;
    overflow: hidden;
  }
  &-enter,
  &-leave-to {
    height: 0;
  }
}
</style>
