<template>
  <div class="toggle">
    <div class="toggle__checkbox">
      <input
        :id="id"
        ref="checkbox"
        type="checkbox"
        class="toggle__checkbox__input"
        :disabled="disabled"
      />
      <i class="toggle__checkbox__switch" />
      <i class="toggle__checkbox__track" />
    </div>
    <label :for="id" class="toggle__label">
      <slot />
    </label>
  </div>
</template>
<script lang="ts" setup>
// Libs
import {
  defineProps,
  withDefaults,
  watch,
  ref,
  onMounted,
  defineEmits,
} from "vue";
const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    label?: string;
    disabled?: boolean;
    id?: string;
  }>(),
  {
    modelValue: false,
    label: "",
    disabled: false,
    id: Math.round(Math.random() * Math.pow(10, 10)).toString(),
  }
);
const emits = defineEmits(["update:modelValue"]);

const checkbox = ref<HTMLInputElement | null>(null);

watch(
  () => props.modelValue,
  () => {
    if (checkbox.value !== null) {
      checkbox.value.checked = props.modelValue;
    }
  }
);
onMounted(() => {
  // Setting values
  if (checkbox.value !== null) {
    checkbox.value.checked = props.modelValue;
    checkbox.value.addEventListener("change", handleChange);
  }
});

function handleChange(): void {
  if (checkbox.value !== null) {
    emits("update:modelValue", checkbox.value.checked);
  }
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";
.toggle {
  &__checkbox {
    position: relative;
    width: 40px;
    height: 20px;
    border-radius: 100px;
    background-color: #ddd;
    overflow: hidden;
    box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05);

    &__switch,
    &__track {
      display: block;
      position: absolute;
      transition: 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    &__switch {
      left: 2px;
      top: 2px;
      bottom: 2px;
      right: 22px;
      background-color: #fff;
      border-radius: 36px;
      z-index: 1;
      transition-property: left, right;
      transition-delay: 0s, 0.05s;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }

    &__track {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.05);
      border-radius: 40px;
    }

    &__input {
      position: absolute;
      display: block;
      cursor: pointer;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 6;
    }
    &__input:checked ~ &__switch {
      right: 2px;
      left: 22px;
      transition: 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      transition-property: left, right;
      transition-delay: 0.05s, 0s;
    }
    &__input:checked ~ &__track {
      box-shadow: inset 0 0 0 20px $primary;
    }
  }
}
</style>
