<template>
  <layout-component>
    <div v-if="apartment" class="apartment">
      <div class="apartment__info">
        <table class="apartment__info__list">
          <tr>
            <th>{{ $t("apartment.serial_number.short") }}</th>
            <td>{{ apartment.serialNumber }}</td>
          </tr>
          <tr>
            <th>{{ $t("apartment.holding_number") }}</th>
            <td>{{ getHoldingNumber(apartment) }}</td>
          </tr>
          <tr>
            <th>{{ $t("apartment.object_number") }}</th>
            <td>{{ apartment.objectNumber }}</td>
          </tr>
          <tr>
            <th>{{ $t("apartment.house_number") }}</th>
            <td>{{ apartment.houseNumber }}</td>
          </tr>
          <tr>
            <th>{{ $t("apartment.apartment_number.short") }}</th>
            <td>{{ apartment.apartmentNumber }}</td>
          </tr>
          <tr>
            <th>{{ $t("apartment.floor") }}</th>
            <td>{{ apartment.floor }}</td>
          </tr>
          <tr>
            <th>{{ $t("apartment.rooms") }}</th>
            <td>{{ apartment.rooms }}</td>
          </tr>
          <tr>
            <th>{{ $t("apartment.size.long") }}</th>
            <td>{{ apartment.size }}</td>
          </tr>
        </table>
        <div class="apartment__info__files">
          <table class="apartment__info__files__list">
            <thead>
              <tr>
                <th>Fil</th>
                <th>Typ</th>
                <th>Uppladdad</th>
                <th
                  class="apartment__info__files__list__item apartment__info__files__list__item--right"
                >
                  Ta bort
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(document, index) in apartment.documents"
                :key="`document_${index}`"
              >
                <td>
                  <a :href="document.url" target="_blank">{{
                    document.name
                  }}</a>
                </td>
                <td>{{ $t(`document.type.${document.type}`) }}</td>
                <td>{{ dateFormat(document.lastModified) }}</td>
                <td class="apartment__info__files__list__item">
                  <button-component
                    class="apartment__info__files__list__item__delete-button"
                    button-size="xs"
                    button-type="outlined"
                    @click="deleteFile(document.type, document.name)"
                  >
                    <i class="far fa-trash-alt" />
                  </button-component>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="apartment__info__files__upload">
            <select-component
              v-model="fileType"
              class="apartment__info__files__upload__select"
              label="Ladda upp fil"
              :options="fileTypes"
            />
            <file-upload-component
              :show-list="false"
              :multiple="true"
              @update:modelValue="uploadFile"
            />
          </div>
        </div>
      </div>
      <div class="apartment__holdings">
        <h2 class="apartment__holdings__header">
          {{ $t("residency.residency") }}
          <button-component
            v-tooltip="'Skapa innehav'"
            :route="{
              replace: true,
              name: 'admin.apartment.residency.create',
              params: { id: apartment.id },
            }"
            class="apartment__holdings__header__add-button"
            button-size="sm"
            button-style="secondary"
          >
            <i class="fa-solid fa-plus" />
          </button-component>
        </h2>
        <table class="apartment__holdings__holding">
          <thead>
            <tr>
              <th>{{ $t("residency.holding_suffix") }}</th>
              <th>{{ $t("residency.from") }}</th>
              <th>{{ $t("residency.end") }}</th>
              <th>{{ $t("residency.type.type") }}</th>
              <th>{{ $t("residency.owner") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(residency, index) of apartment.residencies"
              :key="`residency_${index}`"
              class="apartment__holdings__holding__row"
            >
              <td>
                <router-link
                  class="apartment__holdings__holding__row__link"
                  :to="{
                    name: 'admin.residency.show',
                    params: { id: residency.id },
                  }"
                >
                  {{ getHoldingNumber(apartment, residency) }}
                </router-link>
              </td>
              <td>
                <router-link
                  class="apartment__holdings__holding__row__link"
                  :to="{
                    name: 'admin.residency.show',
                    params: { id: residency.id },
                  }"
                >
                  {{ parseDate(residency.from) }}
                </router-link>
              </td>
              <td>
                <router-link
                  class="apartment__holdings__holding__row__link"
                  :to="{
                    name: 'admin.residency.show',
                    params: { id: residency.id },
                  }"
                >
                  <template v-if="residency.end">
                    {{ parseDate(residency.end) }} </template
                  ><template v-else> - </template>
                </router-link>
              </td>
              <td>
                <router-link
                  class="apartment__holdings__holding__row__link"
                  :to="{
                    name: 'admin.residency.show',
                    params: { id: residency.id },
                  }"
                >
                  {{ $t(`apartment.intent.${residency.type}`) }}
                </router-link>
              </td>
              <td>
                <router-link
                  class="apartment__holdings__holding__row__link"
                  :to="{
                    name: 'admin.residency.show',
                    params: { id: residency.id },
                  }"
                >
                  {{ getOwner(residency) }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

// DTO's
import { IApartmentResidencyDTO } from "../../../shared/dto/apartment-residency.dto";
import { IApartmentResidentDTO } from "../../../shared/dto/apartment-resident.dto";
import { IApartmentDTO } from "../../../shared/dto/apartment.dto";
import { IResidencyDTO } from "../../../shared/dto/residency.dto";

// Bootstrap
import { apartmentService, documentService, modalService } from "../bootstrap";

// Components
import ButtonComponent from "./button.component.vue";
import ConfirmComponent from "./confirm.component.vue";
import FileUploadComponent from "./file-upload.component.vue";
import LayoutComponent from "./layout.component.vue";
import ProgressComponent from "./progress.component.vue";
import ResidencyFormComponent from "./residency-form.component.vue";
import SelectComponent, { IOption } from "./select.component.vue";
import { ModalModule } from "../modules/modal.module";

const $route = useRoute();
const $router = useRouter();
const $i18n = useI18n();

const isCreateResidency = computed<boolean>(() => {
  return $route.name === "admin.apartment.residency.create";
});
const apartment = ref<IApartmentDTO | null>(null);
const apartmentId = computed<number | undefined>(() => {
  let id = $route.params.id;
  if (id === undefined) {
    return undefined;
  }

  // Parse id param as string (if array)
  if (id instanceof Array) {
    if (id.length === 0) {
      return undefined;
    }
    id = id[0];
  }

  return parseInt(id, 10);
});
const fileTypes = computed<IOption[]>(() => {
  return [
    {
      value: "drawing",
      text: $i18n.t("document.type.drawing").toString(),
    },
    {
      value: "other",
      text: $i18n.t("document.type.other").toString(),
    },
  ];
});
const fileType = ref<string>("drawing");

watch(isCreateResidency, () => {
  if (isCreateResidency.value === true && apartment.value !== null) {
    createResidency(apartment.value.id);
  } else {
    hideModal();
  }
});

onMounted(async () => {
  await get();
  if (isCreateResidency.value === true && apartment.value !== null) {
    createResidency(apartment.value.id);
  }
});

async function get() {
  if (apartmentId.value === undefined) {
    return;
  }
  apartment.value = await apartmentService.get(apartmentId.value);
}

let createModal: ModalModule | undefined;

async function createResidency(apartmentId: number): Promise<void> {
  createModal = modalService.create(ResidencyFormComponent, {
    apartmentId,
  });
  createModal.show();
  createModal.onClose((payload: IResidencyDTO | undefined) => {
    if (payload !== undefined) {
      if (apartment.value === null) {
        throw new Error("Apartment is undefined");
      }
      apartment.value.residencies = [
        ...(apartment.value.residencies !== undefined
          ? apartment.value.residencies
          : []),
        { ...payload },
      ];
    }
    reRouteToShow(apartmentId);
  });
  createModal.onHide(() => reRouteToShow(apartmentId));
}

function hideModal(): void {
  if (createModal !== undefined) {
    createModal.hide();
  }
}

function reRouteToShow(apartmentId: number): void {
  $router.replace({
    name: "admin.apartment.show",
    params: { id: apartmentId.toString() },
  });
}

function getOwner(residency: IApartmentResidencyDTO): string {
  if (residency.residents !== undefined) {
    return (
      residency.residents
        .map((tenant: IApartmentResidentDTO) => tenant.lastName)
        .filter(
          (lastname: string | undefined) => lastname !== undefined
        ) as string[]
    )
      .filter(
        (lastname: string, index: number, self: string[]) =>
          self.indexOf(lastname) === index
      )
      .join(", ");
  }
  return "";
}

function getHoldingNumber(
  apartment: IApartmentDTO,
  residency?: IApartmentResidencyDTO
): string {
  if (residency !== undefined) {
    return `${apartment.holdingNumber}:${residency.holdingSuffix}`;
  }
  return `${apartment.holdingNumber}${
    apartment.currentResidency !== undefined
      ? `:${apartment.currentResidency.holdingSuffix}`
      : ""
  }`;
}

async function deleteFile(type: string, filename: string): Promise<void> {
  if (apartment.value === null) {
    return;
  }
  modalService.asPromise(ConfirmComponent, {
    label: `Är du säker på att du vill ta bort filen "${filename}"`,
    type: "confirm",
    beforeClose: async (action: boolean): Promise<void> => {
      if (apartment.value !== null && action === true) {
        await documentService.deleteFile(
          "apartment",
          apartment.value.id,
          filename,
          type
        );
        await get();
      }
    },
  });
}

async function uploadFile(files: File[]): Promise<void> {
  if (apartment.value === null) {
    return;
  }

  const modal = modalService.create(ProgressComponent, { progress: 0 });
  modal.show();
  await documentService.uploadFiles(
    "apartment",
    apartment.value.id,
    files,
    fileType.value,
    (loaded: number, total: number) => {
      modal.setProps({ progress: Math.round((loaded / total) * 100) });
    }
  );
  modal.close();
  await get();
}

function parseDate(date: string): string {
  return date.substring(0, 10);
}

function dateFormat(date: string): string {
  return date.substr(0, 16);
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";
.apartment {
  &__info {
    display: flex;
    align-items: flex-start;
    &__list {
      margin-right: 10px;
      width: 50%;
      @include list(false, "vertical");
    }
    &__files {
      width: 100%;
      margin-left: 10px;
      &__list {
        @include list;
        width: 100%;
        &__item {
          &--right {
            text-align: right;
          }
          &__delete-button {
            display: flex;
            float: right;
          }
        }
      }
      &__upload {
        margin-top: 20px;
        display: flex;
        &__select {
          margin-right: 20px;
          width: 50%;
        }
      }
    }
  }
  &__holdings {
    margin-top: 20px;
    &__header {
      display: flex;
      align-items: center;
      &__add-button {
        margin-left: 10px;
      }
    }
    &__holding {
      @include list(true);
      &__row {
        &__link {
          text-decoration: none;
          color: #000;
          padding: $list-padding;
          display: block;
        }
      }
    }
  }
}
</style>
