<template>
  <layout-component>
    <div v-if="residency" class="residency">
      <h1 class="residency__header">
        Innehav
        <button-component
          v-tooltip="'Redigera innehav'"
          :route="{
            replace: true,
            name: 'admin.residency.edit',
            params: { id: residency.id },
          }"
          class="residency__header__edit-button"
          button-size="sm"
          button-style="secondary"
        >
          <i class="fa-solid fa-pen-to-square" />
        </button-component>
        <button-component
          v-tooltip="'Ta bort innehav'"
          class="residency__header__edit-button"
          button-size="sm"
          button-style="warning"
          @click="deleteResidency(residency.id)"
        >
          <i class="fa-solid fa-xmark" />
        </button-component>
      </h1>
      <div class="residency__info">
        <table class="residency__info__list">
          <tr>
            <th>{{ $t("residency.holding_suffix") }}</th>
            <td>{{ getHoldingNumber(residency) }}</td>
          </tr>
          <tr>
            <th>{{ $t("residency.from") }}</th>
            <td>{{ residency.from }}</td>
          </tr>
          <tr>
            <th>{{ $t("residency.end") }}</th>
            <td>
              <template v-if="residency.end"> {{ residency.end }} </template
              ><template v-else> - </template>
            </td>
          </tr>
          <tr>
            <th>{{ $t("residency.type.type") }}</th>
            <td>
              {{ $t(`apartment.intent.${residency.type}`) }}
            </td>
          </tr>
          <template v-if="residency.apartment">
            <tr>
              <td colspan="2">
                Lägenhet (<router-link
                  :to="{
                    name: 'admin.apartment.show',
                    params: { id: residency.apartment.id },
                  }"
                >
                  #{{ residency.apartment.serialNumber }} </router-link
                >)
              </td>
            </tr>
            <tr>
              <th>Hus</th>
              <td>{{ residency.apartment.houseNumber }}</td>
            </tr>
            <tr>
              <th>Våning</th>
              <td>{{ residency.apartment.floor }}</td>
            </tr>
            <tr v-if="residency.apartment.apartmentNumber">
              <th>Lgh.</th>
              <td>{{ residency.apartment.apartmentNumber }}</td>
            </tr>
            <tr>
              <th>Rum</th>
              <td>{{ residency.apartment.rooms }}</td>
            </tr>
            <tr>
              <th>Storlek</th>
              <td>{{ residency.apartment.size }}</td>
            </tr>
            <tr v-if="residency.apartment.aptusReference">
              <th>Aptusreferens</th>
              <td>{{ residency.apartment.aptusReference }}</td>
            </tr>
            <tr>
              <th>Andel</th>
              <td>{{ formatShare(residency.apartment.share) }}</td>
            </tr>
          </template>
        </table>
        <div class="residency__info__files">
          <table class="residency__info__files__list">
            <thead>
              <tr>
                <th>Fil</th>
                <th>Typ</th>
                <th>Uppladdad</th>
                <th
                  class="residency__info__files__list__item residency__info__files__list__item--right"
                >
                  Ta bort
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(document, index) in residency.documents"
                :key="`document_${index}`"
              >
                <td>
                  <a :href="document.url" target="_blank">{{
                    document.name
                  }}</a>
                </td>
                <td>{{ $t(`document.type.${document.type}`) }}</td>
                <td>{{ dateFormat(document.lastModified) }}</td>
                <td
                  class="residency__info__files__list__item residency__info__files__list__item--right"
                >
                  <button-component
                    button-size="xs"
                    button-type="outlined"
                    @click="deleteFile(document.type, document.name)"
                  >
                    <i class="far fa-trash-alt" />
                  </button-component>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="residency__info__files__upload">
            <select-component
              v-model="fileType"
              class="residency__info__files__upload__select"
              label="Ladda upp fil"
              :options="fileTypes"
            />
            <file-upload-component
              :show-list="false"
              :multiple="true"
              @update:modelValue="uploadFile"
            />
          </div>
        </div>
      </div>
      <div class="residency__residents">
        <h2>Boenden</h2>
        <table class="residency__residents__list">
          <thead>
            <tr>
              <th>Förnamn</th>
              <th>Efternamn</th>
              <th>Telefon</th>
              <th>E-post</th>
              <th v-if="isCondo">Andel</th>
              <th>Personnummer</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(resident, index) in residents"
              :key="`resident_${index}`"
            >
              <td>
                <router-link
                  class="residency__residents__list__row__link"
                  :to="{
                    name: 'admin.resident.show',
                    params: { id: resident.id },
                  }"
                >
                  {{ resident.firstName }}
                </router-link>
              </td>
              <td>
                <router-link
                  class="residency__residents__list__row__link"
                  :to="{
                    name: 'admin.resident.show',
                    params: { id: resident.id },
                  }"
                >
                  {{ resident.lastName }}
                </router-link>
              </td>
              <td>
                <router-link
                  class="residency__residents__list__row__link"
                  :to="{
                    name: 'admin.resident.show',
                    params: { id: resident.id },
                  }"
                >
                  {{ resident.phone }}
                </router-link>
              </td>
              <td>
                <router-link
                  class="residency__residents__list__row__link"
                  :to="{
                    name: 'admin.resident.show',
                    params: { id: resident.id },
                  }"
                >
                  {{ resident.email }}
                </router-link>
              </td>
              <td v-if="isCondo && resident.share">
                <router-link
                  class="residency__residents__list__row__link"
                  :to="{
                    name: 'admin.resident.show',
                    params: { id: resident.id },
                  }"
                >
                  {{ formatShare(resident.share) }}
                </router-link>
              </td>
              <td>
                <router-link
                  class="residency__residents__list__row__link"
                  :to="{
                    name: 'admin.resident.show',
                    params: { id: resident.id },
                  }"
                >
                  {{ resident.nationalId }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { ref, onMounted, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

// DTO's
import { IResidencyDTO } from "../../../shared/dto/residency.dto";
import { IResidentDTO } from "../../../shared/dto/resident.dto";

// Bootstrap
import { residencyService, documentService, modalService } from "../bootstrap";
import { ModalModule } from "../modules/modal.module";

// Components
import ButtonComponent from "./button.component.vue";
import ConfirmComponent from "./confirm.component.vue";
import FileUploadComponent from "./file-upload.component.vue";
import LayoutComponent from "./layout.component.vue";
import ProgressComponent from "./progress.component.vue";
import ResidencyFormComponent from "./residency-form.component.vue";
import SelectComponent, { IOption } from "./select.component.vue";

const $i18n = useI18n();
const $route = useRoute();
const $router = useRouter();

const fileType = ref<string>("other");
const residency = ref<IResidencyDTO | null>(null);

const isEdit = computed(() => {
  return $route.name === "admin.residency.edit";
});

watch(isEdit, (value: boolean) => {
  if (value === true && residency.value !== null) {
    edit(residency.value);
  } else {
    hideModal();
  }
});

const residencyId = computed(() => {
  let id = $route.params.id;
  if (id instanceof Array) {
    id = id[0];
  }
  return parseInt(id, 10);
});

const residents = computed<IResidentDTO[]>(() => {
  return residency.value !== null && residency.value.residents !== undefined
    ? residency.value.residents
    : [];
});

const fileTypes = computed<IOption[]>(() => {
  return [
    {
      value: "other",
      text: $i18n.t("document.type.other").toString(),
    },
  ];
});

onMounted(async () => {
  await get();
  if (isEdit.value === true && residency.value !== null) {
    edit(residency.value);
  }
});

async function get(): Promise<void> {
  if (residencyId.value === undefined) {
    return;
  }

  residency.value = await residencyService.get(residencyId.value);
}

let editModal: ModalModule | undefined;

async function edit(res: IResidencyDTO): Promise<void> {
  editModal = modalService.create(ResidencyFormComponent, {
    residency: res,
  });
  editModal.show();
  editModal.onClose((payload: IResidencyDTO | undefined) => {
    if (payload !== undefined) {
      residency.value = payload;
    }
    reRouteToShow(res.id);
  });
  editModal.onHide(() => reRouteToShow(res.id));
}

function hideModal(): void {
  if (editModal !== undefined) {
    editModal.hide();
  }
}

async function deleteResidency(residencyId: number): Promise<void> {
  await modalService
    .asPromise(ConfirmComponent, {
      label: $i18n.t("residency.delete.confirm").toString(),
      type: "confirm",
      beforeClose: async (action: boolean): Promise<void> => {
        if (action === true) {
          await residencyService.delete(residencyId);
        }
      },
    })
    .then((action?: unknown) => {
      if (action === true) {
        $router.back();
      }
    });
}

function reRouteToShow(residencyId: number): void {
  $router.replace({
    name: "admin.residency.show",
    params: { id: residencyId.toString() },
  });
}

const isCondo = computed(() => {
  return residency.value !== null && residency.value.type === "condo";
});

function deleteFile(type: string, filename: string): void {
  if (residency.value === null) {
    return;
  }

  modalService
    .asPromise(ConfirmComponent, {
      label: $i18n.t("document.delete.confirm", { filename }).toString(),
      type: "confirm",
      beforeClose: async (action: boolean): Promise<void> => {
        if (residency.value !== null && action === true) {
          await documentService.deleteFile(
            "residency",
            residency.value.id,
            filename,
            type
          );
          await get();
        }
      },
    })
    .then((action?: unknown) => {
      if (action === true) {
        $router.back();
      }
    });
}

function uploadFile(files: File[]): void {
  if (residency.value === null) {
    return;
  }

  const modal = modalService.create(ProgressComponent, { progress: 0 });
  modal.show();
  documentService
    .uploadFiles(
      "residency",
      residency.value.id,
      files,
      fileType.value,
      (loaded: number, total: number) => {
        modal.setProps({ progress: Math.round((loaded / total) * 100) });
      }
    )
    .then(async () => {
      modal.close();
      await get();
    });
}

function getHoldingNumber(res: IResidencyDTO): string {
  return `${
    res.apartment !== undefined ? `${res.apartment.holdingNumber}:` : ""
  }${res.holdingSuffix}`;
}

function formatShare(share: number): string {
  return `${share * 100}%`;
}

function dateFormat(date: string): string {
  return date.substr(0, 16);
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.residency {
  &__header {
    display: flex;
    align-items: center;
    &__edit-button {
      margin-left: 10px;
    }
  }
  &__info {
    display: flex;
    align-items: flex-start;
    &__list {
      margin-right: 10px;
      width: 50%;

      @include list(false, "vertical");
    }
    &__files {
      width: 100%;
      margin-left: 10px;
      &__list {
        @include list;
        width: 100%;
        &__item {
          &--right {
            text-align: right;
          }
        }
      }
      &__upload {
        margin-top: 20px;
        display: flex;
        &__select {
          margin-right: 20px;
          width: 50%;
        }
      }
    }
  }
  &__residents {
    margin-top: 20px;
    &__list {
      width: 100%;
      @include list(true);
      &__row {
        &__link {
          text-decoration: none;
          color: #000;
          padding: $list-padding;
          display: block;
        }
      }
    }
  }
}
</style>
