// DTO's
import { IAgreementCommentCreateDTO } from "../../../shared/dto/agreement-comment-create.dto";
import { IAgreementCreateDTO } from "../../../shared/dto/agreement-create.dto";
import { IAgreementUpdateDTO } from "../../../shared/dto/agreement-update.dto";
import { IAgreementDTO } from "../../../shared/dto/agreement.dto";

// Services
import { APIService } from "./api.service";

export class AgreementService {
  public constructor(
    private readonly apiService: APIService
  ) { }

  public async index(): Promise<IAgreementDTO[]> {
    return (await this.apiService.get<IAgreementDTO[]>("/agreement")).body;
  }

  public async get(agreementId: number): Promise<IAgreementDTO> {
    return (await this.apiService.get<IAgreementDTO>(`/agreement/${agreementId}`)).body;
  }
  
  public async create(agreement: IAgreementCreateDTO): Promise<IAgreementDTO> {
    return (await this.apiService.post<IAgreementCreateDTO, IAgreementDTO>("/agreement", agreement)).body;
  }
  
  public async update(agreementId: number, agreement: IAgreementUpdateDTO): Promise<IAgreementDTO> {
    return (await this.apiService.put<IAgreementUpdateDTO, IAgreementDTO>(`/agreement/${agreementId}`, agreement)).body;
  }
  
  public async delete(agreementId: number): Promise<void> {
    await this.apiService.delete<IAgreementDTO>(`/agreement/${agreementId}`);
  }

  public async comment(agreementId: number, comment: IAgreementCommentCreateDTO): Promise<void> {
    await this.apiService.post<IAgreementCommentCreateDTO>(`/agreement/${agreementId}/comment`, comment);
  }

  public async deleteComment(agreementId: number, commentId: number): Promise<void> {
    await this.apiService.delete(`/agreement/${agreementId}/comment/${commentId}`);
  }
}