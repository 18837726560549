<template>
  <layout-component>
    <h1>SMS</h1>
    <div class="sms">
      <div class="sms__numbers">
        <h2>Mottagare</h2>
        <checkbox-component
          v-model="allChecked"
          :indeterminate="allIndeterminate"
        >
          Alla
        </checkbox-component>
        <div
          v-for="(house, houseIndex) in houses"
          :key="`house_${houseIndex}`"
          class="sms__numbers__row sms__numbers__house"
        >
          <div class="sms__numbers__label">
            <div class="sms__numbers__toggle" @click="house.open = !house.open">
              <i v-if="house.open" class="fas fa-minus" />
              <i v-else class="fas fa-plus" />
            </div>
            <checkbox-component
              v-model="house.checked"
              :indeterminate="house.indeterminate"
            >
              {{ house.number }}
            </checkbox-component>
          </div>
          <template v-if="house.open">
            <div
              v-for="(apartment, apartmentIndex) in house.apartments"
              :key="`house_${houseIndex}_apartment_${apartmentIndex}`"
              class="sms__numbers__row sms__numbers__house__apartment"
            >
              <div class="sms__numbers__label">
                <div
                  class="sms__numbers__toggle"
                  @click="apartment.open = !apartment.open"
                >
                  <i v-if="apartment.open" class="fas fa-minus" />
                  <i v-else class="fas fa-plus" />
                </div>
                <checkbox-component
                  v-model="apartment.checked"
                  :indeterminate="apartment.indeterminate"
                >
                  {{ apartment.number }} - {{ apartment.owner }} ({{
                    apartment.numberOfOwners
                  }}st)
                </checkbox-component>
              </div>
              <template v-if="apartment.open">
                <div
                  v-for="(owner, ownerIndex) in apartment.owners"
                  :key="`house_${houseIndex}_apartment_${apartmentIndex}_owner_${ownerIndex}`"
                  class="sms__numbers__row sms__numbers__house__apartment__owner"
                >
                  <div class="sms__numbers__label">
                    <checkbox-component v-model="owner.checked">
                      {{ owner.name }} ({{ owner.phone }})
                    </checkbox-component>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <form class="sms__message" @submit.prevent="send">
        <h2>Meddelande</h2>
        <input-component
          v-model="message"
          class="sms__message__input"
          type="textarea"
        />
        <button-component type="submit" :disabled="!isSendDisabled">
          Skicka
        </button-component>
        <div>
          <i>Antal mottagare markerade: {{ numberOfPhoneNumbersChecked }}</i>
        </div>
      </form>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { computed, ref, onMounted } from "vue";

// DTO's
import { IApartmentResidentDTO } from "../../../shared/dto/apartment-resident.dto";
import { IApartmentDTO } from "../../../shared/dto/apartment.dto";

// Bootstrap
import { apartmentService, smsService } from "../bootstrap";

// Components
import ButtonComponent from "./button.component.vue";
import CheckboxComponent from "./checkbox.component.vue";
import InputComponent from "./input.component.vue";
import LayoutComponent from "./layout.component.vue";

class House {
  public open: boolean = false;
  public set checked(value: boolean) {
    // Set all apartments to checked
    this.apartments = this.apartments.map((apartment: Apartment) => {
      apartment.checked = value;
      return apartment;
    });
  }
  public get checked(): boolean {
    // All apartments are checked
    return (
      this.apartments.findIndex(
        (aparment: Apartment) => aparment.checked === false
      ) === -1
    );
  }

  public get indeterminate(): boolean {
    // Can't be indeterminate if checked
    if (this.checked === true) {
      return false;
    }
    // At least one apartment is checked
    return (
      this.apartments.findIndex(
        (apartment: Apartment) =>
          apartment.checked === true || apartment.indeterminate
      ) !== -1
    );
  }

  public constructor(
    public readonly number: number,
    public apartments: Apartment[]
  ) {}
}

class Apartment {
  public open: boolean = false;
  public set checked(value: boolean) {
    // Set all owners to checked
    this.owners = this.owners.map((owner: Owner) => {
      owner.checked = value;
      return owner;
    });
  }
  public get checked(): boolean {
    // All owners are checked
    return (
      this.owners.findIndex((owner: Owner) => owner.checked === false) === -1
    );
  }
  public get indeterminate(): boolean {
    // Can't be indeterminate if checked
    if (this.checked === true) {
      return false;
    }
    // At least one owner is checked
    return (
      this.owners.findIndex((owner: Owner) => owner.checked === true) !== -1
    );
  }

  public get owner(): string {
    return (
      this.owners
        .map((owner: Owner) => owner.lastName)
        .filter(
          (lastname: string | undefined) => lastname !== undefined
        ) as string[]
    )
      .filter(
        (lastname: string, index: number, self: string[]) =>
          self.indexOf(lastname) === index
      )
      .join(", ");
  }

  public get numberOfOwners(): number {
    return this.owners.length;
  }

  public constructor(public readonly number: string, public owners: Owner[]) {}
}

class Owner {
  public checked: boolean = false;

  public get name(): string | undefined {
    const names: string[] = [
      ...(this.firstName !== undefined ? [this.firstName] : []),
      ...(this.lastName !== undefined ? [this.lastName] : []),
    ];
    return names.length > 0 ? names.join(" ") : undefined;
  }

  public constructor(
    public readonly firstName: string | undefined,
    public readonly lastName: string | undefined,
    public readonly phone: string | undefined
  ) {}
}

const houses = ref<House[]>([]);
const message = ref<string>("");

const allChecked = computed({
  get: () => {
    // All apartments are checked
    return (
      houses.value.findIndex((house: House) => house.checked === false) === -1
    );
  },
  set: (value: boolean) => {
    // Set all apartments to checked
    houses.value = houses.value.map((house: House) => {
      house.checked = value;
      return house;
    });
  },
});

const allIndeterminate = computed<boolean>(() => {
  // Can't be indeterminate if checked
  if (allChecked.value === true) {
    return false;
  }
  // At least one apartment is checked
  return (
    houses.value.findIndex(
      (house: House) => house.checked === true || house.indeterminate
    ) !== -1
  );
});

const checkedPhoneNumbers = computed<string[]>(() => {
  return houses.value.reduce(
    (previousValue: string[], house: House) => [
      ...previousValue,
      ...(house.checked === true || house.indeterminate === true
        ? house.apartments.reduce(
            (previousValue: string[], apartment: Apartment) => [
              ...previousValue,
              ...(apartment.checked === true || apartment.indeterminate === true
                ? apartment.owners.reduce(
                    (previousValue: string[], owner: Owner) => [
                      ...previousValue,
                      ...(owner.checked === true ? [owner.phone || ""] : []),
                    ],
                    []
                  )
                : []),
            ],
            []
          )
        : []),
    ],
    []
  );
});

const isSendDisabled = computed<boolean>(() => {
  return numberOfPhoneNumbersChecked.value > 0 && message.value !== "";
});

const numberOfPhoneNumbersChecked = computed<number>(() => {
  return checkedPhoneNumbers.value.length;
});

onMounted(async () => {
  const apartments: IApartmentDTO[] = await apartmentService.index();
  houses.value = apartments
    // Map to house numbers
    .map((apartment: IApartmentDTO) => apartment.houseNumber)
    // Make unique (by house number)
    .filter(
      (houseNumber: number, index: number, self: number[]) =>
        self.indexOf(houseNumber) === index
    )
    .map(
      (houseNumber: number) =>
        new House(
          houseNumber,
          apartments
            // Filter out apartments with current house number
            .filter(
              (apartment: IApartmentDTO) =>
                apartment.houseNumber === houseNumber
            )
            .reduce(
              (previousValue: Apartment[], apartment: IApartmentDTO) => [
                ...previousValue,
                // Filter out apartments with numbers and current residents with phone numbers
                ...(apartment.apartmentNumber !== undefined &&
                apartment.currentResidency !== undefined &&
                apartment.currentResidency.residents !== undefined &&
                apartment.currentResidency.residents.length > 0 &&
                apartment.currentResidency.residents.findIndex(
                  (resident: IApartmentResidentDTO) =>
                    resident.phone !== undefined
                ) !== -1
                  ? [
                      new Apartment(
                        apartment.apartmentNumber,
                        apartment.currentResidency.residents
                          // Filter out residents with phone numbers
                          .filter(
                            (resident: IApartmentResidentDTO) =>
                              resident.phone !== undefined
                          )
                          .map(
                            (resident: IApartmentResidentDTO) =>
                              new Owner(
                                resident.firstName,
                                resident.lastName,
                                resident.phone
                              )
                          )
                      ),
                    ]
                  : []),
              ],
              []
            )
        )
    );
});

async function send(): Promise<void> {
  await smsService.send({
    recipients: checkedPhoneNumbers.value,
    text: message.value,
  });
  allChecked.value = false;
  message.value = "";
  alert("Meddelande skickat!");
}
</script>
<style lang="scss" scoped>
.sms {
  display: flex;
  &__numbers {
    width: 40%;
    &__label {
      display: flex;
    }
    &__toggle {
      margin-right: 5px;
    }
    &__row:first-child {
      margin-bottom: 10px;
    }
    &__row:not(:first-child) {
      margin-bottom: 10px;
      margin-top: 10px;
    }
    &__house {
      margin-left: 4px;
      &__apartment {
        margin-left: 23px;
        &__owner {
          margin-left: 45px;
        }
      }
    }
  }
  &__message {
    width: 60%;
    &__input {
      width: 100%;
    }
  }
}
</style>
