<template>
  <layout-component>
    <div class="apartments">
      <table class="apartments__list">
        <thead class="apartments__list__header">
          <tr>
            <th>{{ $t("apartment.serial_number.alt") }}</th>
            <th>{{ $t("apartment.house_number") }}</th>
            <th>{{ $t("apartment.floor") }}</th>
            <th>{{ $t("apartment.apartment_number.short") }}</th>
            <th>{{ $t("apartment.aptus_reference") }}</th>
            <th>{{ $t("apartment.rooms") }}</th>
            <th>{{ $t("apartment.size.long") }}</th>
            <th>{{ $t("apartment.intent.intent") }}</th>
            <th>{{ $t("apartment.current_resident") }}</th>
            <th>{{ $t("apartment.current_resident_since") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(apartment, index) in apartments"
            :key="`apartment_${index}`"
            class="apartments__list__row"
          >
            <td>
              <router-link
                class="apartments__list__row__link"
                :to="{
                  name: 'admin.apartment.show',
                  params: { id: apartment.id },
                }"
              >
                {{ apartment.serialNumber }}
              </router-link>
            </td>
            <td>
              <router-link
                class="apartments__list__row__link"
                :to="{
                  name: 'admin.apartment.show',
                  params: { id: apartment.id },
                }"
              >
                {{ apartment.houseNumber }}
              </router-link>
            </td>
            <td>
              <router-link
                class="apartments__list__row__link"
                :to="{
                  name: 'admin.apartment.show',
                  params: { id: apartment.id },
                }"
              >
                {{ apartment.floor }}
              </router-link>
            </td>
            <td>
              <router-link
                class="apartments__list__row__link"
                :to="{
                  name: 'admin.apartment.show',
                  params: { id: apartment.id },
                }"
              >
                {{ apartment.apartmentNumber }}
              </router-link>
            </td>
            <td>
              <router-link
                class="apartments__list__row__link"
                :to="{
                  name: 'admin.apartment.show',
                  params: { id: apartment.id },
                }"
              >
                {{ apartment.aptusReference }}
              </router-link>
            </td>
            <td>
              <router-link
                class="apartments__list__row__link"
                :to="{
                  name: 'admin.apartment.show',
                  params: { id: apartment.id },
                }"
              >
                {{ apartment.rooms }}
              </router-link>
            </td>
            <td>
              <router-link
                class="apartments__list__row__link"
                :to="{
                  name: 'admin.apartment.show',
                  params: { id: apartment.id },
                }"
              >
                {{ apartment.size }}
              </router-link>
            </td>
            <td>
              <router-link
                class="apartments__list__row__link"
                :to="{
                  name: 'admin.apartment.show',
                  params: { id: apartment.id },
                }"
              >
                {{ $t(`apartment.intent.${apartment.intent}`) }}
              </router-link>
            </td>
            <td>
              <router-link
                class="apartments__list__row__link"
                :to="{
                  name: 'admin.apartment.show',
                  params: { id: apartment.id },
                }"
              >
                {{ getOwner(apartment) }}
              </router-link>
            </td>
            <td>
              <router-link
                class="apartments__list__row__link"
                :to="{
                  name: 'admin.apartment.show',
                  params: { id: apartment.id },
                }"
              >
                {{ getOwnerSince(apartment) }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { ref, onMounted } from "vue";

// DTO's
import { IApartmentResidentDTO } from "../../../shared/dto/apartment-resident.dto";
import { IApartmentDTO } from "../../../shared/dto/apartment.dto";

// Bootstrap
import { apartmentService } from "../bootstrap";

// Components
import LayoutComponent from "./layout.component.vue";

const apartments = ref<IApartmentDTO[]>([]);

onMounted(async () => {
  get();
});

async function get() {
  apartments.value = await apartmentService.index();
}

function getOwner(apartment: IApartmentDTO): string {
  if (
    apartment.currentResidency !== undefined &&
    apartment.currentResidency.residents !== undefined
  ) {
    return (
      apartment.currentResidency.residents
        .map((tenant: IApartmentResidentDTO) => tenant.lastName)
        .filter(
          (lastname: string | undefined) => lastname !== undefined
        ) as string[]
    )
      .filter(
        (lastname: string, index: number, self: string[]) =>
          self.indexOf(lastname) === index
      )
      .join(", ");
  }
  return "";
}

function getOwnerSince(apartment: IApartmentDTO): string {
  return apartment.currentResidency !== undefined
    ? parseDate(apartment.currentResidency.from)
    : "";
}

function parseDate(date: string): string {
  return date.substring(0, 10);
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.apartments {
  &__list {
    @include list(true);
    width: 100%;
    &__header {
      position: sticky;
      top: $menu-height + $main-padding;
      &::before {
        content: "&nbsp;";
        height: 10px;
        width: 100%;
        font-size: 0px;
        background-color: #fff;
        position: absolute;
        top: -10px;
      }
    }
    &__row {
      &__link {
        text-decoration: none;
        color: #000;
        padding: $list-padding;
        display: block;
      }
    }
  }
}
</style>
