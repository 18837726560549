import { DefineComponent, defineComponent, createApp, ComponentCustomProperties, App } from "vue";
import { ModalModule, componentType, componentProps } from "../modules/modal.module";
export type globalProps = ComponentCustomProperties & Record<string, any>;

export class ModalService {
  private useCallback?: (app: App) => void;
  public constructor(
    private readonly document: Document,
    private readonly baseComponent: componentType,
  ) { }
  
  public create<C extends componentType>(component: C, props?: componentProps<C>) {
    return new ModalModule(
      this.document,
      this.baseComponent,
      component,
      props,
      this.useCallback
    );
  }

  public asPromise<C extends componentType, T = unknown>(component: C, props?: componentProps<C>): Promise<T | undefined> {
    const modal = this.create(component, props);
    modal.show();
    return new Promise((resolve, reject) => {
      modal.on<T>(['close', 'hide'], resolve);
      modal.onError(reject);
    });
  }

  public attachUseCallback(useCallback: (app: App) => void) {
    this.useCallback = useCallback;
  }
}