// Libs
import { AxiosInstance } from "axios";
import { EditorSettings, Editor } from "tinymce";

// DTO's
import { ISignedUrlDTO } from "../../../shared/dto/signed-url.dto";

interface BlobInfo {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
}

export class TinyMCEModule {
  public constructor(
    private readonly axios: AxiosInstance,
    private readonly getSignedUploadUrl: string,
    private readonly saveCallback?: (editor: Editor) => Promise<void>,
  ) { }

  public readonly settings: Partial<EditorSettings> = {
    plugins: "code print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help",
    toolbar: "SaveButton | formatselect | bold italic strikethrough forecolor backcolor permanentpen"
      + " formatpainter | link image media | alignleft aligncenter alignright alignjustify |"
      + " numlist bullist outdent indent | removeformat | addcomment",

    image_advtab: true,
    // eslint-disable-next-line @typescript-eslint/ban-types
    file_picker_callback: (callback: Function): void => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "*");

      input.onchange = async (): Promise<unknown> => {
        if (input.files === null || input.files.length < 0) { return; }
        
        const file = input.files[0];
        
        // Get signed put url
        const { data: payload } = await this.axios.post<ISignedUrlDTO>(this.getSignedUploadUrl, {
          filename: file.name,
          contentType: file.type,
        });

        // Upload file to S3
        await this.axios.put<File>(payload.putUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
        callback(payload.location, { title: input.files[0].name });
      };

      input.click();
    },
    setup: (editor: Editor): void => {
      if(this.saveCallback === undefined) {
        return;
      }
      editor.ui.registry.addButton("SaveButton", {
        text: "Save",
        onAction: async () => {
          editor.mode.set("readonly");
          if(this.saveCallback !== undefined) {
            await this.saveCallback(editor);
          }
          editor.mode.set("design");
        }
      });
    }
  }
}
