export default {
  "language": {
    "english": "Engelska",
    "swedish": "Svenska"
  },
  "title": "BRF Palett",
  "role": {
    "chairman": "Ordförande"
  },
  "menu": {
    "start": "Start",
    "association": {
      "board": "Styrelsen",
      "association": "Föreningen",
      "apartments": "Lägenhetsregister",
      "statutes": "Stadgar",
      "rules": "Ordningsregler",
      "fire_safety": "Brandsäkerhet",
      "policy": "Policy",
      "annual_meeting": "Årsstämmor",
      "realtor": "Information till mäklare"
    },
    "resident": {
      "resident": "Boende",
      "laundry": "Tvättstuga",
      "courtyard": "Gården",
      "access_control": "Passersystem",
      "electricity_and_internet": "El & Internet",
      "bike_storage": "Cykelförråd",
      "stroller_storage": "Barnvagsförråd",
      "storage_room": "Lägenhetsförråd",
      "common_room": "Gårdslokalen",
      "parking": "Parkering"
    },
    "forms_and_documents": {
      "forms_and_documents": "Blanketter & Dokument",
      "plans": "Ritningar",
      "subletting": "Andrahandsupplåtelse",
      "order_keys": "Beställ Passerbricka/or",
      "renovation": "Renovering",
      "name_change": "Namnändring"
    },
    "contact": {
      "contact": "Kontakt",
      "fault_report": "Felanmälan",
      "other": "Övrigt"
    },
    "admin": {
      "admin": "Admin",
      "apartment": "Lägenheter",
      "resident": "Boenden",
      "document": "Filer",
      "sms": "SMS",
      "agreement": "Avtal"
    },
    "wiki": {
      "wiki": "Wiki",
      "rule_of_procedures": "Arbetsordning"
    },
    "logout": "Logga ut",
    "login": "Logga in"
  },
  "text": {
    "factual_owner": "Faktaägare: {owner}",
    "last_synced": "Senaste uppdaterad: {date}",
    "error": "Tyvärr finns denna artikel inte tillgänglig ännu. Vi jobbar på det"
  },
  "apartment": {
    "apartment": "Lägenhet",
    "holding_number": "Innehav",
    "object_number": "Objektnummer",
    "house_number": "Hus",
    "floor": "Våning",
    "rooms": "Rum",
    "size": {
      "long": "Storlek",
      "alt": "KVM"
    },
    "aptus_reference": "Aptusreferens",
    "apartment_number": {
      "long": "Lägenhetsnummer",
      "short": "lgh."
    },
    "serial_number": {
      "short": "No.",
      "alt": "#"
    },
    "share": "Andel",
    "intent": {
      "intent": "syfte",
      "lease": "@:residency.type.lease",
      "condo": "@:residency.type.condo",
      "vacant": "Vakant"
    },
    "current_resident": "@:residency.owner",
    "current_resident_since": "Sedan"
  },
  "residency": {
    "residency": "Innehav",
    "holding_suffix": "Suffix",
    "from": "Från",
    "end": "Till",
    "type": {
      "type": "Typ",
      "pick": "Välj @:residency.type.type",
      "lease": "Hyresrätt",
      "condo": "Bostadsrätt"
    },
    "owner": "Ägare/Hyresgäst"
  },
  "resident": {
    "resident": "Boende",
    "nationalId": "Personnummer",
    "firstName": "Förnamn",
    "lastName": "Efternamn",
    "phone": "Telefonnummer",
    "email": "E-postadress",
    "list_header": {
      "nationalId": "@:resident.nationalId",
      "firstName": "@:resident.firstName",
      "lastName": "@:resident.lastName",
      "apartmentNumber": "lgh.",
      "intent": "Medlemstyp",
      "phone": "Tele",
      "email": "E-post"
    },
    "filter_only_active": "Visa endast aktiva boenden"
  },
  "document": {
    "name": "Namn",
    "entity": {
      "entity": "Entitet",
      "agreement": "@:agreement.agreement",
      "apartment": "@:apartment.apartment",
      "residency": "@:residency.residency",
      "resident": "@:resident.resident"
    },
    "type": {
      "type": "Typ",
      "credit_score": "Kreditupplysning",
      "drawing": "Ritning",
      "other": "Annat"
    },
    "link": "Länk",
    "size": "Storlek",
    "last_modified": "Senast ändrad"
  },
  "agreement": {
    "agreement": "Avtal",
    "name": "Titel",
    "description": "Beskrivning",
    "from": "Från",
    "to": "Till",
    "ongoing": "Löpande",
    "customer_name": "Företag",
    "customer_number": "Org no.",
    "contact_name": "Kontaktperson",
    "contact_phone": "Telefonnummer",
    "contact_email": "E-postadress",
    "file": {
      "files": "Filer",
      "name": "Fil",
      "last_modified": "Uppladdad",
      "delete": "Ta bort"
    },
    "comment": {
      "comments": "Kommentarer",
      "unknown_user": "Okänd avsändare",
      "add": {
        "header": "Kommentera",
        "submit": "Kommentera"
      },
      "delete": "Ta bort"
    },
    "add": "Lägg till avtal",
    "back": "Tillbaka",
    "save": "Spara",
    "edit": "Redigera",
    "cancel": "Avbryt",
    "delete": "Ta bort"
  },
  "file": {
    "pick": "Välj fil"
  },
  "footer": {
    "name": "@:title",
    "organization": "Org.nummer: {organization_number}",
    "contact": "Kontakt",
    "address": {
      "postal": "Postadress",
      "invoice": "Fakturaadress"
    },
    "facebook_link": "@:title på Facebook",
    "edit_toggle": "Redigeringsläge"
  },
  "confirm": {
    "label": "Är du säker?",
    "yesno": {
      "continue": "Ja",
      "abort": "Nej"
    },
    "confirm": {
      "continue": "Bekräfta",
      "abort": "Avbryt"
    },
    "ok": {
      "continue": "OK",
      "abort": "Avbryt"
    }
  }
}