// Libs
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import * as middlewares from "./middlewares";

// Components
import AgreementComponent from "./components/agreement.component.vue";
import AgreementsComponent from "./components/agreements.component.vue";
import ApartmentComponent from "./components/apartment.component.vue";
import ApartmentsComponent from "./components/apartments.component.vue";
import ResidencyComponent from "./components/residency.component.vue";
import ResidentComponent from "./components/resident.component.vue";
import ResidentsComponent from "./components/residents.component.vue";
import SmsComponent from "./components/sms.component.vue";
import DocumentsComponent from "./components/documents.component.vue";
import LoginComponent from "./components/login.component.vue";
import TextPageComponent from "./components/text-page.component.vue";
import { authService } from "./bootstrap";

const routes: RouteRecordRaw[] = [
  { path: "/", redirect: { name: "start" } },
  { path: "/start", name: "start", component: TextPageComponent, props: { name: "start" } },
  { path: '/login', name: "login", component: LoginComponent },
  { path: "/logout", name: "logout", component: { beforeRouteEnter: middlewares.logout(authService) } },
  { path: "/association", children: [
    { path: "board", name: "association.board", component: TextPageComponent, props: { name: "association.board" } },
    { path: "apartments", name: "association.apartments", component: TextPageComponent, props: { name: "association.apartments" } },
    { path: "statutes", name: "association.statutes", component: TextPageComponent, props: { name: "association.statutes" } },
    { path: "rules", name: "association.rules", component: TextPageComponent, props: { name: "association.rules" } },
    { path: "fire_safety", name: "association.fire_safety", component: TextPageComponent, props: { name: "association.fire_safety" } },
    { path: "policy", name: "association.policy", component: TextPageComponent, props: { name: "association.policy" } },
    { path: "annual_meeting", name: "association.annual_meeting", component: TextPageComponent, props: { name: "association.annual_meeting" } },
    { path: "realtor", name: "association.realtor", component: TextPageComponent, props: { name: "association.realtor" } },
  ]},
  { path: "/resident", children: [
    { path: "laundry", name: "resident.laundry", component: TextPageComponent, props: { name: "resident.laundry" } },
    { path: "courtyard", name: "resident.courtyard", component: TextPageComponent, props: { name: "resident.courtyard" } },
    { path: "access_control", name: "resident.access_control", component: TextPageComponent, props: { name: "resident.access_control" } },
    { path: "electricity_and_internet", name: "resident.electricity_and_internet", component: TextPageComponent, props: { name: "resident.electricity_and_internet" } },
    { path: "bike_storage", name: "resident.bike_storage", component: TextPageComponent, props: { name: "resident.bike_storage" } },
    { path: "stroller_storage", name: "resident.stroller_storage", component: TextPageComponent, props: { name: "resident.stroller_storage" } },
    { path: "storage_room", name: "resident.storage_room", component: TextPageComponent, props: { name: "resident.storage_room" } },
    { path: "common_room", name: "resident.common_room", component: TextPageComponent, props: { name: "resident.common_room" } },
    { path: "parking", name: "resident.parking", component: TextPageComponent, props: { name: "resident.parking" } },
  ]},
  { path: "/forms_and_documents", children: [
    { path: "plans", name: "forms_and_documents.plans", component: TextPageComponent, props: { name: "forms_and_documents.plans" } },
    { path: "subletting", name: "forms_and_documents.subletting", component: TextPageComponent, props: { name: "forms_and_documents.subletting" } },
    { path: "order_keys", name: "forms_and_documents.order_keys", component: TextPageComponent, props: { name: "forms_and_documents.order_keys" } },
    { path: "renovation", name: "forms_and_documents.renovation", component: TextPageComponent, props: { name: "forms_and_documents.renovation" } },
    { path: "name_change", name: "forms_and_documents.name_change", component: TextPageComponent, props: { name: "forms_and_documents.name_change" } },
  ]},
  { path: "/admin", children: [
    { path: "apartment", children: [
      { path: "", name: "admin.apartment", component: ApartmentsComponent },
      { path: ":id", name: "admin.apartment.show", component: ApartmentComponent },
      { path: ":id/residency/create", name: "admin.apartment.residency.create", component: ApartmentComponent },
    ]},
   { path: "residency", children: [
      { path: ":id", name: "admin.residency.show", component: ResidencyComponent },
      { path: ":id/edit", name: "admin.residency.edit", component: ResidencyComponent },
    ]},
    { path: "resident", children: [
      { path: "", name: "admin.resident", component: ResidentsComponent },
      { path: ":id", name: "admin.resident.show", component: ResidentComponent },
      { path: ":id/edit", name: "admin.resident.edit", component: ResidentComponent },
    ]},
    { path: "sms", name: "admin.sms", component: SmsComponent },
    { path: "agreement", children: [
      { path: "", name: "admin.agreement", component: AgreementsComponent },
      { path: ":id", name: "admin.agreement.show", component: AgreementComponent },
    ]},
    { path: "document", name: "admin.document", component: DocumentsComponent },
    { path: "wiki", children: [
      { path: "rule_of_procedures", name: "wiki.rule_of_procedures", component: TextPageComponent, props: { name: "forms_and_documents.plans" } }
    ]}
  ]},
  { path: "/contact", children: [
    { path: "fault_report", name: "contact.fault_report", component: TextPageComponent, props: { name: "contact.fault_report" } },
    { path: "other", name: "contact.other", component: TextPageComponent, props: { name: "contact.other" } },
  ]}
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
});