export default {
  "language": {
    "english": "English",
    "swedish": "Swedish"
  },
  "title": "BRF Palett",
  "role": {
    "chairman": "Chairman"
  },
  "menu": {
    "start": "Start",
    "association": {
      "board": "The board",
      "association": "The Association",
      "apartments": "Apartment Register",
      "statutes": "Statues",
      "rules": "Rules",
      "fire_safety": "Fire Safety",
      "policy": "Policy",
      "annual_meeting": "Annual Meeting",
      "realtor": "Information to Realtor"
    },
    "resident": {
      "resident": "Residents",
      "laundry": "Laundry",
      "courtyard": "Courtyard",
      "access_control": "Access Control System",
      "electricity_and_internet": "Electricity & Internet",
      "bike_storage": "Bike Storage",
      "stroller_storage": "Stroller Storage",
      "storage_room": "Apartment Storage Room",
      "common_room": "Common Room",
      "parking": "Parking"
    },
    "forms_and_documents": {
      "forms_and_documents": "Forms & Documents",
      "plans": "Plans & Drawings",
      "subletting": "Subletting",
      "order_keys": "Order Access Keys",
      "renovation": "Renovation",
      "name_change": "Name Change"
    },
    "contact": {
      "contact": "Contact",
      "fault_report": "Faulty Report",
      "other": "Other"
    },
    "admin": {
      "admin": "Admin",
      "apartment": "Apartments",
      "sms": "SMS",
      "agreement": "Agreements"
    },
    "wiki": {
      "wiki": "Wiki",
      "rule_of_procedures": "Rule Of Procedures"
    },
    "logout": "Logout",
    "login": "Login"
  },
  "text": {
    "factual_owner": "Factual owner: {owner}",
    "last_synced": "Last updated: {date}",
    "error": "This article is currently not available. We're working on it. Some of our articles are only availabe in {0}"
  },
  "agreement": {
    "agreement": "Agreement",
    "name": "Title",
    "description": "Description",
    "from": "From",
    "to": "To",
    "ongoing": "Ongoing",
    "customer_name": "Company",
    "customer_number": "Company org. no.",
    "contact_name": "Contact person",
    "contact_phone": "Phone number",
    "contact_email": "E-mail address",
    "file": {
      "files": "Files",
      "name": "File",
      "last_modified": "Uploaded",
      "delete": "Delete"
    },
    "comment": {
      "comments": "Comments",
      "unknown_user": "Unknown user",
      "add": {
        "header": "Comment",
        "submit": "Submit"
      },
      "delete": "Delete"
    },
    "add": "Add agreement",
    "back": "Back",
    "save": "Save",
    "edit": "Edit",
    "cancel": "Cancel",
    "delete": "Delete"
  },
  "file": {
    "pick": "Pick file"
  },
  "footer": {
    "name": "@:title",
    "organization": "Organizationl No: {organization_number}",
    "contact": "Contact",
    "address": {
      "postal": "Postal Address",
      "invoice": "Invoice Address"
    },
    "facebook_link": "@:title on Facebook",
    "edit_toggle": "Edit mode"
  }
}