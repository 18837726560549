// Libs
import { createApp } from "vue";
import tinymce from "tinymce";
import VueObserveVisibility from "vue-observe-visibility"

// feature flags
globalThis.__VUE_OPTIONS_API__ = process.env.NODE_ENV === "development";
globalThis.__VUE_PROD_DEVTOOLS__ = process.env.NODE_ENV === "development";

// Tooltip
import tooltip from "./directives/tooltip.js";

// TinyMCE
(window as any).tinymce = tinymce;

// Bootstrap
import { localeService, modalService } from './bootstrap';

// Router
import { router } from './router';

const app = createApp({ template: '<router-view />' });

app.use(router);
app.use(localeService.init());
app.use(VueObserveVisibility);
app.directive("tooltip", tooltip);
app.mount('#app');

modalService.attachUseCallback(app => {
  app.use(router);
  app.use(localeService.init());
  app.use(VueObserveVisibility);
  app.directive("tooltip", tooltip);
});