// DTO's
import { IResidentCreateDTO } from "../../../shared/dto/resident-create.dto";
import { IResidentUpdateDTO } from "../../../shared/dto/resident-update.dto";
import { IResidentDTO } from "../../../shared/dto/resident.dto";

// Services
import { APIService } from "./api.service";

export class ResidentService {
  public constructor(
    private readonly apiService: APIService
  ) { }

  public async index(): Promise<IResidentDTO[]> {
    return (await this.apiService.get<IResidentDTO[]>("/resident/")).body;
  }
  public async get(residentId: number): Promise<IResidentDTO> {
    return (await this.apiService.get<IResidentDTO>(`/resident/${residentId}`)).body;
  }

  public async create(resident: IResidentCreateDTO): Promise<IResidentDTO> {
    return (await this.apiService.post<IResidentCreateDTO, IResidentDTO>("/resident", resident)).body;
  }

  public async update(residentId: number, resident: IResidentUpdateDTO): Promise<IResidentDTO> {
    return (await this.apiService.patch<IResidentUpdateDTO, IResidentDTO>(`/resident/${residentId}`, resident)).body;
  }
}