<template>
  <layout-component>
    <table class="documents">
      <thead>
        <tr>
          <th>{{ $t("document.name") }}</th>
          <th>{{ $t("document.entity.entity") }}</th>
          <th>{{ $t("document.type.type") }}</th>
          <th>{{ $t("document.link") }}</th>
          <th>{{ $t("document.size") }}</th>
          <th>{{ $t("document.last_modified") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(document, index) in documents" :key="`document_${index}`">
          <td>
            <a :href="document.url" target="_blank">{{ document.name }}</a>
          </td>
          <td>
            {{ $t(`document.entity.${document.entityType}`) }}
          </td>
          <td>
            <template v-if="document.type">
              {{ $t(`document.type.${document.type}`) }} </template
            ><template v-else> - </template>
          </td>
          <td>
            <router-link :to="document.route">
              {{ document.linkView }}
            </router-link>
          </td>
          <td>
            {{ document.size }}
          </td>
          <td>
            {{ document.lastModified }}
          </td>
        </tr>
      </tbody>
    </table>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { ref, onMounted } from "vue";
import { RouteLocationRaw } from "vue-router";

// DTO's
import { entityType, IDocumentDTO } from "../../../shared/dto/document.dto";

// Bootstrap
import { documentService } from "../bootstrap";

// Components
import LayoutComponent from "./layout.component.vue";

interface IDocumentViewModel {
  name: string;
  entityType: entityType;
  type?: string;
  url: string;
  size: string;
  lastModified: string;
  linkView: string;
  route: RouteLocationRaw;
}

const documents = ref<IDocumentViewModel[]>([]);

onMounted(async () => {
  await get();
});

async function get(): Promise<void> {
  documents.value = (await documentService.all()).reduce(
    (previousValue: IDocumentViewModel[], document: IDocumentDTO) => {
      let linkView: string | undefined;
      let route: RouteLocationRaw | undefined;
      if (
        document.entityType === "agreement" &&
        document.agreement !== undefined
      ) {
        linkView = `Avtal: ${document.agreement.name}`;
        route = {
          name: "admin.agreement.show",
          params: { id: document.agreement.id.toString() },
        };
      } else if (
        document.entityType === "apartment" &&
        document.apartment !== undefined
      ) {
        linkView = `Lgh: ${document.apartment.houseNumber}:${document.apartment.apartmentNumber}`;
        route = {
          name: "admin.apartment.show",
          params: { id: document.apartment.id.toString() },
        };
      } else if (
        document.entityType === "residency" &&
        document.residency !== undefined
      ) {
        if (
          document.residency.apartment !== undefined &&
          document.residency.apartment.apartmentNumber !== undefined
        ) {
          linkView = `Innehav för lgh. ${document.residency.apartment.houseNumber}:${document.residency.apartment.apartmentNumber}`;
        } else {
          linkView = `Innehav: ${document.residency.id}`;
        }
        route = {
          name: "admin.residency.show",
          params: { id: document.residency.id.toString() },
        };
      } else if (
        document.entityType === "resident" &&
        document.resident !== undefined
      ) {
        linkView = `${document.resident.nationalId}`;
        route = {
          name: "admin.resident.show",
          params: { id: document.resident.id.toString() },
        };
      }
      return [
        ...previousValue,
        ...(linkView !== undefined && route !== undefined
          ? [
              {
                name: document.name,
                entityType: document.entityType,
                type: document.type,
                url: document.url,
                size: `${
                  Math.round((document.size / 1024 / 1024) * 100) / 100
                } MB`,
                lastModified: document.lastModified,
                linkView,
                route,
              } as IDocumentViewModel,
            ]
          : []),
      ] as IDocumentViewModel[];
    },
    []
  );
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.documents {
  @include list;
  width: 100%;
}
</style>
