// Libs
import { AxiosProgressEvent } from "axios";

// DTO's
import { IDocumentDTO } from "../../../shared/dto/document.dto";
import { ISignedUrlDTO } from "../../../shared/dto/signed-url.dto";

// Services
import { APIService } from "./api.service";

type FileUploadCallback = (loaded: number, total: number) => void;

export class DocumentService {
  public constructor(
    private readonly apiService: APIService,
  ) { }

  public async all(): Promise<IDocumentDTO[]> {
    return (await this.apiService.get<IDocumentDTO[]>("/document")).body;
  }

  public async uploadFiles(entity: string, id: number, files: File[], type?: string, callback?: FileUploadCallback): Promise<void> {
    const totalSize: number = files.reduce((previousValue: number, file: File) => previousValue + file.size, 0);
    let loaded: number = 0;
    for(const file of files) {
      const query: string = [
        ...(type !== undefined ? [`type=${type}`] : []),
        `filename=${file.name}`,
        `content-type=${file.type}`
      ].join("&");
      const uploadUrl: string =  (await this.apiService.get<ISignedUrlDTO>(`/${entity}/${id}/upload-url?${query}`)).body.putUrl;
      await this.apiService.put<File>(uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
        ...(callback !== undefined ? { onUploadProgress: (event: AxiosProgressEvent): void => {

          callback(loaded + event.loaded, totalSize);
        } } : undefined)
      });
      loaded = loaded + file.size;
    }
  }

  public async deleteFile(entity: string, id: number, filename: string, type?: string): Promise<void> {
    const query: string = [
      ...(type !== undefined ? [`type=${type}`] : []),
      `filename=${filename}`,
    ].join("&");

    await this.apiService.delete(`/${entity}/${id}/delete-file?${query}`);
  }
}