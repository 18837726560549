<template>
  <div class="layout">
    <header-component />
    <menu-component />
    <div class="layout__main">
      <side-menu-component class="layout__main__side-menu" />
      <div class="layout__main__content">
        <slot />
      </div>
    </div>
    <footer-component class="layout__main__footer" />
  </div>
</template>
<script lang="ts" setup>
// Libs
import { getCurrentInstance } from "vue";

// Components
import HeaderComponent from "./header.component.vue";
import FooterComponent from "./footer.component.vue";
import MenuComponent from "./menu.component.vue";
import SideMenuComponent from "./side-menu.component.vue";
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  &__main {
    @include desktop {
      padding-top: $main-padding;
    }
    @include mobile {
      padding-top: $menu-height + $main-padding;
    }
    @include container;
    display: flex;
    flex-grow: 2;
    align-items: flex-start;
    &__side-menu {
      @include mobile {
        display: none;
      }
      margin-right: 10px;
      width: 250px;
      position: -webkit-sticky;
      position: sticky;
      top: $menu-height + 10px;
      overflow-y: auto;
      max-height: calc(100vh - ($menu-height + 10px + $footer-height + 10px));
    }
    &__content {
      width: 100%;
      padding: 0px 10px;
      align-self: normal;
    }
    &__footer {
      margin-top: 10px;
    }
  }
}
</style>
