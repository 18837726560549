<template>
  <div class="file-upload">
    <input
      ref="input"
      class="file-upload__input"
      type="file"
      :multiple="multiple"
      @input="onInput"
    />
    <button-component button-style="secondary" @click="open">
      {{ $t("file.pick") }}
    </button-component>
    <ul v-if="showList" class="file-upload__list">
      <li v-for="(file, index) of files" :key="`file_${index}`">
        {{ file.name }}
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { withDefaults, ref, defineEmits } from "vue";

// Components
import ButtonComponent from "./button.component.vue";

const props = withDefaults(
  defineProps<{
    showList?: boolean;
    multiple?: boolean;
  }>(),
  {
    showList: true,
    multiple: false,
  }
);

const $emit = defineEmits(["update:modelValue"]);

const input = ref<HTMLInputElement | null>(null);
const files = ref<File[]>([]);

function open(): void {
  if (input.value === null) {
    return;
  }
  input.value.click();
}

function onInput(): void {
  setFiles();
  $emit("update:modelValue", files.value);
}

function setFiles(): void {
  const tempFiles: File[] = [];
  if (input.value !== null && input.value.files !== null) {
    for (let a: number = 0; a < input.value.files.length; a++) {
      tempFiles.push(input.value.files[a]);
    }
  }
  files.value = tempFiles;
}
</script>
<style lang="scss" scoped>
.file-upload {
  &__input {
    display: none;
  }
}
</style>
