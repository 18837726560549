<template>
  <div class="header">
    <div class="header__container">
      <div class="header__logo">
        <router-link :to="{ name: 'start' }">
          <img src="../../resources/img/logo.png" alt="" />
        </router-link>
      </div>
      <div class="header__locale">
        <a
          class="header__locale__link"
          href=""
          @click.prevent="changeLanguage('en')"
        >
          <img src="../../resources/img/gb.png" alt="gb"
        /></a>
        <a
          class="header__locale__link"
          href=""
          @click.prevent="changeLanguage('sv')"
        >
          <img src="../../resources/img/se.png" alt="se"
        /></a>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { useI18n } from "vue-i18n";
import { localeService } from "../bootstrap";

const $i18n = useI18n();

function changeLanguage(language: string): void {
  localeService.changeLanguage(language);
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";
.header {
  height: $header-height;
  @include mobile {
    display: none;
  }
  &__container {
    @include container;
    display: flex;
    justify-content: space-between;
  }
  @include mobile {
    margin-top: $menu-height;
  }
  &__logo {
    height: 75px;
    padding: 15px 0px;
    img {
      height: 100%;
    }
  }
  &__locale {
    margin-top: 10px;
    &__link {
      margin-left: 5px;
    }
  }
}
</style>
