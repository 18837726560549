<template>
  <div class="upload-progress">
    <i class="upload-progress__spinner fas fa-spinner" />
    <p v-if="progress" class="upload-progress__percentage">{{ progress }}%</p>
  </div>
</template>
<script lang="ts" setup>
// Libs

defineProps<{
  progress?: number;
}>();
</script>
<style lang="scss" scoped>
@keyframes example {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(90deg);
  }
  37.5% {
    transform: rotate(135deg);
  }
  50% {
    transform: rotate(180deg);
  }
  62.5% {
    transform: rotate(225deg);
  }
  75% {
    transform: rotate(270deg);
  }
  87.5% {
    transform: rotate(315deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.upload-progress {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__spinner {
    font-size: 80px;
    animation-name: example;
    animation-duration: 0.75s;
    animation-timing-function: step-end;
    animation-iteration-count: infinite;
  }
  &__percentage {
    margin-top: 20px;
    font-size: 25px;
  }
}
</style>
