// Libs
import Axios from "axios";
import { detect } from "detect-browser";

// Services
import { StorageService } from "./services/storage.service";
import { AuthService } from "./services/auth.service";
import { BroadcastService } from "../../shared/services/broadcast.service";
import { TextService } from "./services/text.service";
import { APIService } from "./services/api.service";
import { LocaleService } from "./services/locale.service";
import { BrowserService } from "./services/browser.service";
import { AgreementService } from "./services/agreement.service";
import { DocumentService } from "./services/document.service";
import { ApartmentService } from "./services/apartment.service";
import { ResidencyService } from "./services/residency.service";
import { ResidentService } from "./services/resident.service";
import { ModalService } from "./services/modal.service";
import { SmsService } from "./services/sms.service";

// Components
import ModalBaseComponent from "./components/modal-base.component.vue";

export const axios = Axios.create();
export const browserService = new BrowserService(window, detect);
axios.defaults.baseURL = process.env.API_BASE_URL;
export const broadcastService = new BroadcastService();
export const storageService = new StorageService("storage", new BroadcastService());
export const localeService = new LocaleService("sv", {
  sv: "swedish",
  en: "english",
}, broadcastService, storageService, browserService);
const apiService = new APIService(process.env.API_BASE_URL || "", axios, new BroadcastService(), localeService);
export const authService = new AuthService(apiService, storageService, broadcastService);
export const textService = new TextService(apiService);
export const agreementService = new AgreementService(apiService);
export const documentService = new DocumentService(apiService);
export const apartmentService = new ApartmentService(apiService);
export const residencyService = new ResidencyService(apiService);
export const residentService = new ResidentService(apiService);
export const modalService = new ModalService(document, ModalBaseComponent);
export const smsService = new SmsService(apiService);