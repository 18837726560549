<template>
  <div class="select">
    <label
      v-if="hasLabel"
      class="select__label select__label--active select__label--gray"
    >
      <slot name="label">{{ label }}</slot>
    </label>
    <i
      class="select__caret fas fa-caret-down"
      :class="{ 'text-muted': disabled }"
    />
    <label
      v-if="hasPlaceholder && hiddenValue === '' && !isOptionsEmpty"
      :class="[
        'select__select-placeholder',
        { 'select__select-placeholder--with-label': hasLabel },
      ]"
    >
      <slot name="placeholder">{{ placeholder }}</slot>
    </label>
    <label
      v-if="isOptionsEmpty"
      :class="[
        'select__select-placeholder',
        { 'select__select-placeholder--with-label': hasLabel },
      ]"
    >
      <slot name="empty">{{ emptyLabel }}</slot>
    </label>
    <select
      ref="select"
      class="select__control"
      :disabled="disabled"
      @input="onInput"
    >
      <option v-if="hasPlaceholder" value hidden default />
      <template v-for="(optionGroup, optionGroupIndex) in options">
        <optgroup
          v-if="optionGroup.children !== undefined"
          :key="`option_${optionGroupIndex}`"
          :label="optionGroup.text"
        >
          <option
            v-for="(option, index) in optionGroup.children"
            :key="`option_child_${index}`"
            :disabled="
              option.disabled !== undefined && option.disabled === true
            "
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </optgroup>
        <option
          v-else
          :key="`option_${optionGroupIndex}_else`"
          :disabled="
            optionGroup.disabled !== undefined && optionGroup.disabled === true
          "
          :value="optionGroup.value"
        >
          {{ optionGroup.text }}
        </option>
      </template>
    </select>
  </div>
</template>
<script lang="ts" setup>
import {
  withDefaults,
  watch,
  ref,
  defineEmits,
  computed,
  useSlots,
  onMounted,
} from "vue";

export interface IOption {
  value: string;
  text: string;
  disabled?: boolean;
  children?: IOption[];
}

const $emit = defineEmits(["update:modelValue"]);
const $slots = useSlots();

const props = withDefaults(
  defineProps<{
    label?: string;
    placeholder?: string;
    emptyLabel?: string;
    modelValue?: string;
    options: IOption[];
    disabled?: boolean;
  }>(),
  {
    emptyLabel: "",
    modelValue: "",
    disabled: false,
  }
);

const select = ref<HTMLSelectElement | null>(null);

watch(
  () => props.modelValue,
  (value: string) => {
    if (select.value !== null) {
      select.value.value = value;
      hiddenValue.value = value;
    }
  }
);

watch(
  () => props.options,
  (options: IOption[]) => {
    if (
      options.find((option: IOption) => option.value === props.modelValue) ===
      undefined
    ) {
      $emit("update:modelValue", "");
    }
  }
);

const hiddenValue = ref<string>("");

const hasLabel = computed<boolean>(() => {
  return props.label !== undefined;
});

const hasPlaceholder = computed<boolean>(() => {
  return !!$slots.placeholder !== undefined || props.placeholder !== undefined;
});

const isOptionsEmpty = computed<boolean>(() => {
  return props.options.length === 0;
});

const isActive = computed<boolean>(() => {
  return props.modelValue !== "";
});

onMounted(() => {
  if (select.value !== null) {
    hiddenValue.value = props.modelValue;
    select.value.value = props.modelValue;
  }
});

function onInput(): void {
  if (select.value !== null) {
    hiddenValue.value = select.value.value;
    $emit("update:modelValue", select.value.value);
  }
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.select {
  display: flex;
  flex-direction: column;
  position: relative;

  &__label {
    position: relative;
    top: 20px;
    font-size: 1rem;
    cursor: text;
    transition: transform 0.2s ease-out, color 0.2s ease-out,
      -webkit-transform 0.2s ease-out;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(12px);
    color: gray;
    &--active {
      color: $primary;
      transform: translateY(-14px) scale(0.8);
      transform-origin: 0 0;
    }
    &--gray {
      color: gray;
    }
  }
  &__caret {
    position: absolute;
    right: 0px;
    bottom: 13px;
  }
  &__select-placeholder {
    min-height: 3rem;
    width: 100%;
    color: gray;
    line-height: 3rem;
    position: absolute;
    pointer-events: none;
    &--with-label {
      top: 20px;
    }
  }
  #{selector-unify(&, textarea)}__control {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    min-height: 2rem;
    resize: none;
  }
  &__control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 0;
    outline: none;
    min-height: 3rem;
    width: 100%;
    font-size: 16px;
    margin: 0px;
    padding: 0px;
    box-shadow: none;
    box-sizing: content-box;
    transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
    &:focus {
      border-bottom: 1px solid $primary;
      box-shadow: 0 1px 0 0 $primary;
    }
    &:disabled {
      color: gray;
    }
  }
}
select.select__control {
  -webkit-appearance: none;
  border-radius: 0px;
}
</style>
