// DTO's
import { IApartmentDTO } from "../../../shared/dto/apartment.dto";
import { IHouseDTO } from "../../../shared/dto/house.dto";

// Services
import { APIService } from "./api.service";

export class ApartmentService {
  public constructor(
    private readonly apiService: APIService,
  ) { }

  public async index(): Promise<IApartmentDTO[]> {
    return (await this.apiService.get<IApartmentDTO[]>("/apartment")).body;
  }
  public async get(apartmentId: number): Promise<IApartmentDTO> {
    return (await this.apiService.get<IApartmentDTO>(`/apartment/${apartmentId}`)).body;
  }
  
  public async houses(): Promise<IHouseDTO[]> {
    return (await this.apiService.get<IHouseDTO[]>("/house")).body;
  }
}