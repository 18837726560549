// DTO's
import { IResidencyCreateDTO } from "../../../shared/dto/residency-create.dto";
import { IResidencyUpdateDTO } from "../../../shared/dto/residency-update.dto";
import { IResidencyDTO } from "../../../shared/dto/residency.dto";

// Services
import { APIService } from "./api.service";

export class ResidencyService {
  public constructor(
    private readonly apiService: APIService
  ) { }

  public async index(): Promise<IResidencyDTO[]> {
    return (await this.apiService.get<IResidencyDTO[]>("/residency")).body;
  }
  public async get(residencyId: number): Promise<IResidencyDTO> {
    return (await this.apiService.get<IResidencyDTO>(`/residency/${residencyId}`)).body;
  }
  public async create(residency: IResidencyCreateDTO): Promise<IResidencyDTO> {
    return (await this.apiService.post<IResidencyCreateDTO, IResidencyDTO>("/residency", residency)).body;
  }
  public async update(residencyId: number, residency: IResidencyUpdateDTO): Promise<IResidencyDTO> {
    return (await this.apiService.patch<IResidencyUpdateDTO, IResidencyDTO>(`/residency/${residencyId}`, residency)).body;
  }
  public async delete(residencyId: number): Promise<void> {
    await this.apiService.delete(`/residency/${residencyId}`);
  }
}