<template>
  <layout-component><text-component :name="name" /></layout-component>
</template>
<script lang="ts" setup>
// Components
import LayoutComponent from "./layout.component.vue";
import TextComponent from "./text.component.vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});
</script>
