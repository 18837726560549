<template>
  <ul
    v-if="menu"
    class="side-menu"
  >
    <li
      v-for="(item, index) in menu"
      :key="`menu_${index}`"
      :class="['side-menu__item', { 'side-menu__item--divider': isMenuItemDivider(item) }]"
    >
      <router-link
        v-if="isMenuItemRoute(item)"
        class="side-menu__item__link"
        active-class="side-menu__item__link--active"
        :to="item.route"
      >
        {{ $t(`menu.${item.name}`) }}
      </router-link>
    </li>
  </ul>
</template>
<script lang="ts" setup>
// Libs
import { useRoute } from "vue-router";
import { ref, watch, computed, onMounted, onUnmounted } from "vue";

import { ISubscription } from "../../../shared/services/broadcast.service";

// Bootstrap
import { authService } from "../bootstrap";

// Menu
import { isActive, menu, getMenu as baseGetMenu, isMenuItemRoute, isMenuItemDivider } from "../menu";

const $route = useRoute();
watch($route, () => {
  menu.value = getMenu();
});
  
let onLoginOrLogoutSubscription: ISubscription | undefined;

const menu = ref<menu[] | null>(null);

onMounted(() => {
  menu.value = getMenu();
  onLoginOrLogoutSubscription = authService.onLoginOrLogout(() => {
    menu.value = getMenu();
  });
});

onUnmounted(() => {
  if(onLoginOrLogoutSubscription !== undefined) {
    onLoginOrLogoutSubscription.unsubscribe();
  }
});

function getMenu(): menu[] | null {
  const item: menu | undefined = baseGetMenu(authService.isAuth).find((menu: menu) => "route" in menu && isActive($route, menu));
  
  if(item !== undefined && "route" in item && item.children !== undefined) {
    return item.children;
  }
  return null;
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.side-menu {
  margin: 0px;
  padding: 10px 0px 0px;
  background-color: darken($primary, 10%);
  list-style-type: none;
  border: 1px solid #ccc;
  &__item {
    &--divider {
        border: 1px solid transparent;
    }
    &__link {
      padding: 0px 20px;
      height: 50px;
      display: flex;
      align-items: center;
      text-decoration: none;
      background-color: #eee;
      border-bottom: 1px solid #ddd;
      color: #000;
      &:hover {
        color: #333;
      }
      &--active {
        background-color: #ccc;
      }
    }
  }
}
</style>