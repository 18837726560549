// DTO's
import { ISmsHistoriesDTO } from "../../../shared/dto/sms-histories.dto";
import { ISmsSendDTO } from "../../../shared/dto/sms-send.dto";

// Services
import { APIService } from "../../../shared/services/api.service";

export interface ISmsHistoryResponse<T, R> { smsHistory: T[]; pagination: R; }

export class SmsService {
  public constructor(
    private readonly apiService: APIService,
  ) { }

  public async send(sms: ISmsSendDTO): Promise<void> {
    await this.apiService.post("/sms/send", sms);
  }

  public async history(page: number = 1): Promise<ISmsHistoriesDTO> {
    return (await this.apiService.get<ISmsHistoriesDTO>(`/sms?page=${page}`)).body;
  }
}
