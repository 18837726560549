<template>
  <modal-layout-component
    class="residency-form"
    :header="isEdit ? 'Redigera Innehav' : 'Nytt innehav'"
    :show-buttons="true"
    :valid="isValid"
    :loading="loading"
    @abort="$emit('hide')"
    @save="save"
  >
    <div class="residency-form__apartment">
      <select-component
        v-model="form.apartmentId"
        class="residency-form__apartment__select"
        :options="apartmentOptions"
        :label="$t('apartment.apartment')"
        :disabled="loading"
      />
      <input-component
        class="residency-form__apartment__holding-number"
        :value="selectedApartmentHoldingNumber"
        :label="$t('residency.residency')"
        :disabled="true"
      />
      <input-component
        v-model="form.suffix"
        class="residency-form__apartment__suffix"
        :label="$t('residency.holding_suffix')"
        :disabled="loading"
      />
    </div>
    <div class="residency-form__dates">
      <input-component
        v-model="form.from"
        :label="$t('residency.from')"
        :disabled="loading"
      />
      <input-component
        v-model="form.end"
        :label="$t('residency.end')"
        :disabled="loading"
      />
    </div>
    <div class="residency-form__type">
      <select-component
        v-model="form.type"
        :options="typeOptions"
        :label="$t('residency.type.type')"
        :placeholder="$t('residency.type.pick')"
        :disabled="loading"
      />
    </div>
    <div class="residency-form__residents">
      <h2 class="residency-form__residents__header">
        Boende
        <button-component
          button-size="sm"
          button-style="primary"
          @click="createResident()"
        >
          Skapa ny boende
        </button-component>
      </h2>
      <table class="residency-form__residents__table">
        <thead>
          <tr>
            <th>Förnamn</th>
            <th>Efternamn</th>
            <th>Personnummer</th>
            <th>Andel</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(resident, index) in residencyResidents"
            :key="`resident_${index}`"
            class="residency-form__residents__resident"
          >
            <td>{{ resident.firstName }}</td>
            <td>{{ resident.lastName }}</td>
            <td>{{ resident.nationalId }}</td>
            <td>
              <input-component
                v-model="resident.share"
                :disabled="!isCondo"
                placeholder="Andel i procent"
              />
            </td>
            <td>
              <button-component
                v-tooltip="'Ta bort'"
                button-size="sm"
                button-style="secondary"
                @click="deleteResident(resident.id)"
              >
                <i class="fa-solid fa-minus" />
              </button-component>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="residency-form__residents__add">
        <h2 class="residency-form__residents__add__header">Lägg till boende</h2>
        <div class="residency-form__residents__add__content">
          <select-component
            v-model="selectedResident"
            placeholder="Välj boende"
            :options="residentOptions"
          />
          <button-component
            v-tooltip="'Lägg till'"
            button-size="sm"
            button-style="secondary"
            :disabled="selectedResident === ''"
            @click="addToResidents(selectedResident)"
          >
            <i class="fa-solid fa-plus" />
          </button-component>
        </div>
      </div>
    </div>
  </modal-layout-component>
</template>
<script lang="ts" setup>
// Libs
import { computed, ref, onMounted, defineEmits } from "vue";
import { useI18n } from "vue-i18n";

// DTO's
import { IApartmentDTO } from "../../../shared/dto/apartment.dto";
import { IResidencyDTO } from "../../../shared/dto/residency.dto";
import { IResidentDTO } from "../../../shared/dto/resident.dto";

// Bootstrap
import {
  apartmentService,
  modalService,
  residencyService,
  residentService,
} from "../bootstrap";

// Components
import ButtonComponent from "./button.component.vue";
import InputComponent from "./input.component.vue";
import ModalLayoutComponent from "./modal-layout.component.vue";
import ResidentFormComponent from "./resident-form.component.vue";
import SelectComponent, { IOption } from "./select.component.vue";

interface IResidencyForm {
  apartmentId: string;
  suffix: string;
  from: string;
  end: string;
  type: string;
}

interface IResident {
  id: number;
  firstName?: string;
  lastName?: string;
  nationalId: string;
  share: string;
}

const $t = useI18n().t;
const $emit = defineEmits(["close", "hide", "save", "error", "event"]);

const props = defineProps<{
  residency?: IResidencyDTO;
  apartmentId?: number;
}>();

const form = ref<IResidencyForm>({
  apartmentId: "",
  suffix: "",
  from: "",
  end: "",
  type: "",
});
const residencyResidents = ref<IResident[]>([]);
const selectedResident = ref<string>("");
const loading = ref<boolean>(false);
const apartments = ref<IApartmentDTO[]>([]);
const residents = ref<IResidentDTO[]>([]);
const typeOptions = [
  { value: "lease", text: $t("residency.type.lease") },
  { value: "condo", text: $t("residency.type.condo") },
];

const isEdit = computed<boolean>(() => props.residency !== undefined);
const isCondo = computed<boolean>(() => props.residency?.type === "condo");
const apartmentOptions = computed<IOption[]>(() => {
  return apartments.value.map((apartment: IApartmentDTO) => ({
    value: apartment.id.toString(),
    text: `#${apartment.serialNumber}${
      apartment.apartmentNumber !== undefined
        ? ` (${apartment.houseNumber}:${apartment.apartmentNumber})`
        : ""
    }`,
  }));
});
const selectedApartment = computed<IApartmentDTO | undefined>(() => {
  if (form.value.apartmentId === "") {
    return undefined;
  }
  return apartments.value.find(
    (apartment: IApartmentDTO) =>
      apartment.id === parseInt(form.value.apartmentId)
  );
});
const selectedApartmentHoldingNumber = computed<string | undefined>(() => {
  return selectedApartment.value === undefined
    ? undefined
    : selectedApartment.value.holdingNumber;
});
const residentOptions = computed<IOption[]>(() => {
  return residents.value
    .filter(
      (resident: IResidentDTO) =>
        residencyResidents.value.findIndex(
          (residencyResident: IResident) => residencyResident.id === resident.id
        ) === -1
    )
    .map((resident: IResidentDTO) => ({
      value: resident.id.toString(),
      text: `${resident.lastName}, ${resident.firstName} (${resident.nationalId})`,
    }));
});
const isValid = computed<boolean>(() => {
  return (
    form.value.apartmentId !== "" &&
    form.value.suffix !== "" &&
    form.value.from !== "" &&
    form.value.type !== ""
  );
});

onMounted(async () => {
  await getApartments();
  await getResidents();
  if (props.residency !== undefined) {
    if (props.residency.apartment !== undefined) {
      form.value.apartmentId = props.residency.apartment.id.toString();
    }
    form.value.suffix = props.residency.holdingSuffix;
    form.value.from = props.residency.from;
    if (props.residency.end !== undefined) {
      form.value.end = props.residency.end;
    }
    form.value.type = props.residency.type;

    if (props.residency.residents !== undefined) {
      residencyResidents.value = props.residency.residents.map(
        (resident: IResidentDTO) => ({
          id: resident.id,
          firstName: resident.firstName,
          lastName: resident.lastName,
          nationalId: resident.nationalId,
          share: resident.share !== undefined ? resident.share.toString() : "",
        })
      );
    }
  } else if (props.apartmentId !== undefined) {
    form.value.apartmentId = props.apartmentId.toString();
  }
});

async function save(): Promise<void> {
  loading.value = true;
  let residency: IResidencyDTO;
  if (props.residency !== undefined) {
    residency = await residencyService.update(props.residency.id, {
      apartmentId: parseInt(form.value.apartmentId, 10),
      holdingSuffix: form.value.suffix,
      from: form.value.from,
      end: form.value.end !== "" ? form.value.end : null,
      type: form.value.type,
      residents: residencyResidents.value.map((resident) => ({
        id: resident.id,
        ...(form.value.type === "condo" && resident.share !== ""
          ? { share: parseInt(resident.share, 10) }
          : {}),
      })),
    });
  } else {
    residency = await residencyService.create({
      apartmentId: parseInt(form.value.apartmentId, 10),
      holdingSuffix: form.value.suffix,
      from: form.value.from,
      ...(form.value.end !== "" ? { end: form.value.end } : undefined),
      type: form.value.type,
      residents: residencyResidents.value.map((resident) => ({
        id: resident.id,
        ...(form.value.type === "condo" && resident.share !== ""
          ? { share: parseInt(resident.share, 10) }
          : {}),
      })),
    });
  }
  loading.value = false;
  $emit("close", residency);
}

async function createResident(): Promise<void> {
  const modal = modalService.create(ResidentFormComponent);
  modal.show();
  modal.onClose((resident: IResidentDTO | undefined) => {
    if (resident !== undefined) {
      residents.value.push(resident);
      addToResidents(resident.id);
    }
  });
}

async function addToResidents(residentId: string | number): Promise<void> {
  if (typeof residentId === "string") {
    residentId = parseInt(residentId, 10);
  }
  const resident: IResidentDTO | undefined = residents.value.find(
    (resident) => resident.id === residentId
  );
  if (resident !== undefined) {
    residencyResidents.value.push({
      id: resident.id,
      firstName: resident.firstName,
      lastName: resident.lastName,
      nationalId: resident.nationalId,
      share: resident.share !== undefined ? `${resident.share * 100}` : "",
    });
  }
}

function deleteResident(residencyResidentId: number): void {
  residencyResidents.value = residencyResidents.value.filter(
    (residencyResident) => residencyResident.id !== residencyResidentId
  );
}

async function getApartments(): Promise<void> {
  apartments.value = await apartmentService.index();
}
async function getResidents(): Promise<void> {
  residents.value = await residentService.index();
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";
.residency-form {
  &__apartment {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    &__select {
      width: 40%;
    }
    &__holding-number {
      width: 30%;
      text-align: right;
    }
    &__suffix {
      width: 30%;
    }
  }
  &__dates {
    display: flex;
    gap: 10px;
    * {
      flex-grow: 1;
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
  }
  &__residents {
    margin-top: 30px;
    &__header {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
    }
    &__table {
      width: 100%;
      @include list(false);
    }
    &__add {
      margin-top: 20px;
      &__header {
        margin: 0px;
        font-size: 16px;
      }
      &__content {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;

        *:first-child {
          flex-grow: 1;
        }
      }
    }
  }
}
</style>
