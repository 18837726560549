<template>
  <Editor
    v-if="tinyMCEInitWith"
    api-key="g43iwed5djdi09375usso84wl8r59fx1zvjkzw025vrbbv58"
    :model-value="modelValue"
    :init="tinyMCEInitWith"
    @update:modelValue="onInput"
  />
</template>
<script lang="ts" setup>
// Libs
import Editor from "@tinymce/tinymce-vue";
import { EditorSettings } from "tinymce";
import { withDefaults, defineEmits, ref, onMounted } from "vue";

import { axios } from "../bootstrap";

// Modules
import { TinyMCEModule } from "../modules/tinymce.module";

const props = withDefaults(
  defineProps<{
    modelValue: string;
    inline: boolean;
  }>(),
  {
    value: "",
    inline: false,
  }
);

const emit = defineEmits(["update:modelValue", "save"]);

const tinyMCEInitWith = ref<Partial<EditorSettings> | null>(null);

function onInput(value: string): void {
  emit("update:modelValue", value);
}
onMounted(() => {
  tinyMCEInitWith.value = new TinyMCEModule(
    axios,
    "/resource/get-signed-url",
    async () => emit("save")
  ).settings;
});
</script>
<style lang="scss" scoped></style>
