import { IAgreementDTO } from "./agreement.dto";
import { IApartmentDTO } from "./apartment.dto";
import { IResidencyDTO } from "./residency.dto";
import { IResidentDTO } from "./resident.dto";

export type entityType = "agreement" | "apartment" | "residency" | "resident";

export interface IDocumentDTO {
  name: string;
  entityType: entityType;
  entityId: number;
  type?: string;
  url: string;
  size: number;
  lastModified: string;
  agreement?: IAgreementDTO;
  apartment?: IApartmentDTO;
  residency?: IResidencyDTO;
  resident?: IResidentDTO;
}