<template>
  <div class="modal-layout">
    <div class="modal-layout__header">
      <template v-if="header">
        <h1 class="modal-layout__header__text">
          {{ header }}
        </h1>
        <button-component
          class="modal-layout__header__close-button"
          button-type="outlined"
          button-size="sm"
          @click="$emit('abort')"
        >
          <i class="fa-solid fa-xmark" />
        </button-component>
      </template>
      <slot v-else name="header" />
    </div>
    <div class="modal-layout__content">
      <slot />
    </div>
    <div class="modal-layout__footer">
      <template v-if="showButtons">
        <button-component
          button-style="primary"
          :loading="loading"
          :disabled="!valid || loading"
          @click="$emit('save')"
        >
          Spara
        </button-component>
        <button-component
          button-style="secondary"
          :disabled="loading"
          @click="$emit('abort')"
        >
          Avbryt
        </button-component>
      </template>
      <slot v-else name="footer" />
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { withDefaults, defineEmits } from "vue";

// Components
import ButtonComponent from "./button.component.vue";

const props = withDefaults(
  defineProps<{
    header?: string;
    showButtons?: boolean;
    valid?: boolean;
    loading?: boolean;
  }>(),
  {
    showButtons: false,
    valid: true,
    loading: false,
  }
);

const $emit = defineEmits(["save", "abort"]);
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.modal-layout {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header {
    padding: 20px;
    background-color: gray;
    display: flex;
    width: 100%;
    justify-content: space-between;
    &__text {
      font-size: 18px;
      margin: 0px;
      color: #eee;
    }
    &__close-button {
      color: #f00;
    }
  }
  &__content {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__footer {
    border-top: 1px solid #aaa;
    background-color: #eee;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
</style>
