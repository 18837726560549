import { runInThisContext } from "vm";
import { DefineComponent, createApp, ComponentCustomProperties, App, h } from "vue";
import { BroadcastService } from "../../../shared/services/broadcast.service";

export type componentType = DefineComponent<any, any, any, any, any>;
export type componentProps<C extends componentType> = InstanceType<C>["$props"];
export type globalProps = ComponentCustomProperties & Record<string, any>;

export class ModalModule<C extends componentType = componentType> {
  private app: App | null = null;
  private wrapper?: HTMLElement;
  private readonly broadcastService = new BroadcastService();

  public constructor(
    private readonly document: Document,
    private readonly baseComponent: componentType,
    private readonly component: C,
    private readonly props?: componentProps<C>,
    private readonly useCallback?: (app: App) => void
  ) {
    // Create app
    this.create();

    this.broadcastService.on(["close", "hide", "error"], () => {
      this.destroy();
    });
  }

  private create() {
    // Already existing will abort
    if(this.app !== null) { return; }

    // Create app
    this.app = createApp({
      setup: () => () => h(this.baseComponent, {
        component: this.component,
        broadcastService: this.broadcastService,
        ...(this.props !== undefined ? { props: this.props } : {})
      })
    });

    if(this.useCallback !== undefined) {
      this.useCallback(this.app);
    }
  }

  public show() {
    // Create app if not existing
    if(this.app === null) { this.create(); return; }
    
    // Create wrpper element and attach it to the body
    this.wrapper = this.document.createElement('div');
    this.document.body.appendChild(this.wrapper);
    
    // Mount app to wrapper
    this.app.mount(this.wrapper);
  }

  public close() {
    this.broadcastService.emit("close");
  }

  public hide() {
    this.broadcastService.emit("hide");
  }

  public setProps(props: componentProps<C>): void {
    this.broadcastService.emit("setProps", props);
  }

  public onClose<T>(callback: (payload?: T) => void): void {
    this.broadcastService.on("close", callback);
  }
  public onHide(callback: () => void): void {
    this.broadcastService.on("hide", callback);
  }
  public onError(callback: (error: unknown) => void): void {
    this.broadcastService.on("error", callback);
  }
  public on<T>(event: string | string[], callback: (payload?: T) => void): void {
    this.broadcastService.on(event, callback);
  }


  private destroy() {
    // Remove all subscriptions
    this.broadcastService.unsubscribeAll();

    if(this.app !== null) {
      // Unmount app
      this.app.unmount();
      // Unset app
      this.app = null;
    }
    if(this.wrapper !== undefined) {
      // Remove wrapper from body
      this.document.body.removeChild(this.wrapper);
    }
  }
}